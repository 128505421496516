import { CREATE_PRESENTATION_TODO, UPDATE_TODO } from "routes/ApiEndpoints";
import { CENTRAL_DATA_MANAGEMENT_MICROSERVICE, ERROR, SUCCESS } from "Constants";
import {
  t,
  translate,
} from "components/CentralDataMangement/translation/Translation";
import Button from "components/common/atoms/Button";
import Title from "components/common/atoms/Title";
import LabelWithCKEditorField from "components/common/molecules/LabelWithCKEditorField";
import { ApiCall } from "components/common/services/ApiServices";
import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import CustomNotify from "components/common/atoms/CustomNotify";
import { useSelector } from "react-redux";
import { selectAuth } from "features/auth/AuthSlice";
import * as ENDPOINTS from "../../../routes/ApiEndpoints";
import LabelWithTextAreaField from "components/common/molecules/LabelWithTextAreaField";
import {
  OptionProps,
  ValidationRules,
} from "components/common/utlis/TypeAnnotations";
import {
  validateForm,
  validateMultiSelectField,
  validateRequired,
  validateTokens,
} from "components/common/services/ValidationService";
import GetQueryParams from "components/common/services/GetQueryParams";
import secureLocalStorage from "react-secure-storage";
import SelectWithSearch from "components/common/atoms/SelectWithSearch";
import { mapToSelect } from "components/common/utlis/MapToSelect";
import { clearDestinationPath } from "features/NavigationSlice";
import { useDispatch } from "react-redux";
import CheckBoxField from "components/common/atoms/CheckBoxField";

interface PresentationProps {
  description: string;
  pdfName: string;
  pdfLink: string;
  bodyToken: string;
  contacts: OptionProps[];
  includeCv: boolean;
}

const PresentationPage: React.FC = () => {
  const userAuth = useSelector(selectAuth);
  const {
    vacancyId,
    candidateId,
    functionName,
    presentationId,
    prestype,
    companyId,
    type,
    presentationTo,
    officeId
  } = useParams<{
    vacancyId: string;
    candidateId: string;
    functionName: string;
    presentationId: string;
    prestype: string;
    companyId: string;
    type: string;
    presentationTo: string;
    officeId: string;
  }>();

  const navigate = useNavigate();
  const location = useLocation();
  const [formData, setFormData] = useState<PresentationProps>({
    description: "",
    pdfName: "",
    pdfLink: "",
    bodyToken: "",
    contacts: [],
    includeCv: true
  });
  const [dropDown, setDropDown] = useState({
    contacts: [] as OptionProps[],
  });

  const [flag, setFlag] = useState(false);
  const queryParams = GetQueryParams();
  const destination = queryParams.get("destination") ?? "/todos/overview";

  const [errors, setErrors] = useState({
    description: "",
    contacts: "",
  });

  const validation = (
    name: string,
    value: string | boolean,
    isSingleFieldValidation: boolean = false
  ) => {
    let bodyTokens = [] as string[];
    bodyTokens = (formData.bodyToken?.match(/\[\w+\]/g) ?? []) as string[];

    const validationRules: ValidationRules = {
      description: [validateRequired, validateTokens(bodyTokens)],
      contacts: ((type === "vacancy" && presentationTo === 'company') ||
        (type === "candidate" && prestype === "single" && presentationTo === 'company'))
        ? [validateMultiSelectField]
        : [],
    };

    const validationErrors = validateForm(
      { ...formData, [name]: value },
      validationRules,
      isSingleFieldValidation ? name : undefined
    );

    if (isSingleFieldValidation && Object.keys(errors).length > 0) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: validationErrors[name],
      }));
    } else {
      setErrors(validationErrors as any);
    }

    if (Object.keys(validationErrors).length > 0) {
      return false;
    }

    return true;
  };

  const handleFieldChange = (event: any, editor: any) => {
    const content = editor.getData();
    setFormData((prevData) => ({
      ...prevData,
      description: content,
    }));

    if (flag === true) {
      validation("description", content, true);
    }
    setFlag(true);
  };

  const lang_key = secureLocalStorage.getItem("site_lang") ?? "en";
  useEffect(() => {
    const getCompanyResponsiblePerson = async () => {
      const responsibledata = {
        vacancyId: vacancyId !== "undefined" && vacancyId !== "null" && vacancyId !== "" ? vacancyId : null,
        functionName: functionName !== "undefined" && functionName !== "null" && functionName !== "" ? functionName : null,
        companyId: companyId !== "undefined" && companyId !== "null" && companyId !== "" ? companyId : null,
        candidateId: candidateId,
        type: type,
        prestype: prestype,
        lang: lang_key,
        presentationTo: presentationTo
      };

      const response = await ApiCall.service(
        ENDPOINTS.GET_COMPANY_RESPONSIBLE_PERSON,
        "POST",
        responsibledata,
        true,
        CENTRAL_DATA_MANAGEMENT_MICROSERVICE
      );

      if (response.status === 200) {
        setFormData((prev) => ({
          ...prev,
          description: response.data.emailContent,
          bodyToken: response.data.tokens,
        }));
        setDropDown((prev) => ({
          contacts: response.data?.companyUserDetails
            ? mapToSelect(response.data?.companyUserDetails)
            : [],
        }));
        if (response.data?.fileName) {
          setFormData((prev) => ({
            ...prev,
            pdfName: response.data?.fileName,
            pdfLink: response.data?.filePath,
          }));
        }
      }
    };

    const getPresentationDocument = async () => {
      const docDetails = {
        candidateId: candidateId,
        functionName: functionName !== "undefined" && functionName !== "null" && functionName !== "" ? functionName : null,
        lang: lang_key,
        vacancyId: vacancyId !== "undefined" && vacancyId !== "null" && vacancyId !== "" ? vacancyId : null,
        companyId: companyId !== "undefined" && companyId !== "null" && companyId !== "" ? companyId : null,
      };
      const url = presentationTo === 'office' ? '/createCandidateResume' : ENDPOINTS.CREATE_PRESENTATION_DOCUMENT;
      const response = await ApiCall.service(
        url,
        "POST",
        docDetails,
        true,
        "candidate-creation"
      );
      if (response.status === 200) {
        setFormData((prev) => ({
          ...prev,
          pdfName: response.data.fileName,
          pdfLink: response.data.filePath,
        }));
      }
    };

    getCompanyResponsiblePerson();
    if ((prestype === "single" && presentationTo === 'company') || presentationTo === 'office') {
      getPresentationDocument();
    }
  }, []);

  const dispatch = useDispatch();

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    const { name, value } = e.target as HTMLInputElement;
    if (validation(name, value)) {
      let singlePresentationData = {
        vacancyId: vacancyId !== "undefined" && vacancyId !== "null" && vacancyId !== "" ? vacancyId : null,
        functionName: functionName !== "undefined" && functionName !== "null" && functionName !== "" ? functionName : null,
        companyId: companyId !== "undefined" && companyId !== "null" && companyId !== "" ? companyId : null,
        officeId: officeId !== "undefined" && officeId !== "null" && officeId !== "" ? officeId : null,
        presentationId: presentationId !== "undefined" && presentationId !== "null" && presentationId !== "" ? presentationId : null,
        candidateId: candidateId,
        emailContent: formData.description,
        userId: userAuth.userId,
        logInfo: "Presentation",
        stage: 4,
        submitType: "accept",
        todoType: "presentation",
        prestype: prestype,
        type: type,
        lang: lang_key,
        presentationTo: presentationTo,
        includeCv: formData.includeCv
      };

      if (prestype === "single" && presentationTo === 'company') {
        const updateResponse = await ApiCall.service(
          UPDATE_TODO,
          "POST",
          singlePresentationData,
          true,
          CENTRAL_DATA_MANAGEMENT_MICROSERVICE
        );
        if (updateResponse.status === 200) {
          const presentationData = {
            ...singlePresentationData,
            stage: 4,
            contacts: formData.contacts,
          };
          const response = await ApiCall.service(
            CREATE_PRESENTATION_TODO,
            "POST",
            presentationData,
            true,
            CENTRAL_DATA_MANAGEMENT_MICROSERVICE
          );
          if (response.status === 200) {
            if (response.data[1] === "success") {
              navigate("/manage/follow-up");
            } else {
              navigate(destination);
            }
            CustomNotify({
              type: response.data[1],
              message: t(response.data[0]),
            });
          } else {
            CustomNotify({ type: "error", message: t("Some error occurred") });
          }
        }
      } else if (prestype === 'multiple' && presentationTo === "company") {
        let { data } = location.state;
        const multiplePresentationData = {
          ...data,
          emailContent: formData.description,
          prestype: prestype,
          lang: lang_key,
          contacts: formData.contacts,
          vacancyId: vacancyId,
          presentationTo: presentationTo,
          includeCv: formData.includeCv
        };
        const updateResponse = await ApiCall.service(
          UPDATE_TODO,
          "POST",
          multiplePresentationData,
          true,
          CENTRAL_DATA_MANAGEMENT_MICROSERVICE
        );
        if (updateResponse.status === 200) {
          CustomNotify({
            type: updateResponse.msg[0],
            message: t(updateResponse.msg[1]),
          });
          if (updateResponse.msg[0] === "success") {
            navigate("/manage/follow-up");
          } else {
            navigate(destination);
          }
        } else {
          CustomNotify({ type: "error", message: t("Some error occurred") });
        }
      } else if ((prestype === 'single' && presentationTo === "candidate") || presentationTo === "office") {
        if (officeId !== "undefined" && officeId !== "null" && officeId !== "" && presentationTo === "office") {
          singlePresentationData = {
            ...singlePresentationData,
            officeId: officeId
          }
        }
        dispatch(clearDestinationPath());

        const updateResponse = await ApiCall.service(
          'presentationMail',
          "POST",
          singlePresentationData,
          false,
          CENTRAL_DATA_MANAGEMENT_MICROSERVICE
        );
        if (updateResponse.status === 200) {
          navigate(destination);
          CustomNotify({
            type: SUCCESS,
            message: t("Email sent successfully"),
          });
        } else {
          CustomNotify({ type: "error", message: t("Some error occurred") });
        }

      } else if (prestype === 'multiple' && presentationTo === "candidate") {
        let { data } = location.state;
        const multiplePresentationData = {
          ...data,
          emailContent: formData.description,
          prestype: prestype,
          lang: lang_key,
          contacts: formData.contacts,
          vacancyId: vacancyId,
          presentationTo: presentationTo,
          includeCv: formData.includeCv
        };
        const updateResponse = await ApiCall.service(
          'presentationMail',
          "POST",
          multiplePresentationData,
          true,
          CENTRAL_DATA_MANAGEMENT_MICROSERVICE
        );
        if (updateResponse.status === 200) {
          navigate(destination);
          CustomNotify({
            type: SUCCESS,
            message: t("Emails sent successfully"),
          });
        } else {
          CustomNotify({ type: "error", message: t("Some error occurred") });
        }
      }
    }
  };


  const handleSelectChange = (selectedOption: any, fieldName: string) => {
    setFormData((prevData) => ({
      ...prevData,
      [fieldName]: selectedOption,
    }));
    validation(fieldName, selectedOption, true);
  };

  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { name, checked } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: checked,
    }));
  };

  return (
    <>
      <Title title={t("Presentation")} />
      <form onSubmit={handleSubmit}>
        <div className="row search-bar">
          {((type === "vacancy" && presentationTo === 'company') ||
            (type === "candidate" && prestype === "single" && presentationTo === 'company')) && (
              <div className="col-sm-12 col-md-6 col-lg-4">
                <SelectWithSearch
                  name="contacts"
                  title={t("Company contacts")}
                  isMandatory={true}
                  search={true}
                  options={dropDown.contacts}
                  value={formData.contacts}
                  onChange={(e) => handleSelectChange(e, "contacts")}
                  isMulti={true}
                  isTranslate={true}
                  error={errors.contacts}
                />
              </div>
            )}
          <div className="d-flex row">
            {prestype === "single" && (
              <div className="col-4 ms-2 p-2">
                <span>
                  <a
                    href={formData.pdfLink}
                    target="_blank"
                    className="fw-bold link-color"
                    rel="noreferrer"
                  >
                    {t("Click here ")}
                  </a>
                  &nbsp;
                  {presentationTo === 'candidate' ? t("to preview vacancy details") : t("to see the candidate preview")}
                </span>
              </div>
            )}
            <div className="col-4 ms-2 p-2">
              <CheckBoxField
                label={presentationTo === 'candidate' ? t("Include vacancy details") : t("Include CV")}
                name="includeCv"
                onChangeHandler={handleChange}
                isChecked={formData.includeCv == true}
                className="document-checkbox"
                lineHeight="1.1vw"
                id="includeCv"
              />
            </div>
          </div>
        </div>
        <div className="col-12">
          <LabelWithCKEditorField
            isMandatory={false}
            label=""
            name="description"
            handleChange={(event, editor) => handleFieldChange(event, editor)}
            value={formData.description}
            id={`description`}
            error={errors.description}
          />
        </div>
        {/* {prestype === "multiple" && */}
        <div className="col-3">
          <LabelWithTextAreaField
            name={"bodyTokens"}
            handleChange={() => { }}
            value={
              formData.bodyToken &&
              Object.values(
                JSON.parse(formData.bodyToken) as Record<string, string>
              ).join("\n")
            }
            label={t("Tokens")}
            rows={4}
          />
        </div>
        {/* } */}
        {/* <div className="mb-3">
          Preview candidate details here : {" "}
          <a href={formData.pdfLink} target="_blank" className="fw-bold link-color">
            {formData.candidateName}
          </a>
        </div> */}
        <div className="row backPadding">
          <div className="col-md-6 align-self-center">
            <Link
              to={destination}
              // to={location.state?.type === "vacancy" ? `/matching-list/vacancy/${vacancyId}` : `/matching-list/candidate/${candidateId}`}
              className="back-btn text-decoration-underline"
            >
              {t("Back")}
            </Link>
          </div>

          <div className="col-md-6 d-flex justify-content-end">
            <Button
              title={t("Present")}
              type="submit"
              className="form-button shadow-none"
            />
          </div>
        </div>
      </form>
    </>
  );
};
export default translate(PresentationPage);
