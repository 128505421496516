import { useEffect, useState } from "react";
import {
  CandidateSearchData,
  CandidateData,
  dropDownList,
  intialCandidateSearchData,
  intialDropDownList,
  candidateAdvSearchData,
  defaultCandidateAdvSearchData,
} from "./Interface";
import { ApiCall } from "components/common/services/ApiServices";
import AccessControl from "components/common/services/RolesAndPermissions/AccessControl";
import LinkTo from "components/common/atoms/LinkTo";
import { t } from "components/CentralDataMangement/translation/Translation";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import RightSideBar, {
  FollowUpEntity,
} from "components/common/atoms/RightSideBar";
import { useSelector } from "react-redux/es/hooks/useSelector";
import { selectAuth } from "features/auth/AuthSlice";
import CustomNotify from "components/common/atoms/CustomNotify";
import {
  CALL_3CX,
  CANDIDATE_MICROSERVICE,
  CENTRAL_DATA_MANAGEMENT_MICROSERVICE,
  CandidateStatus,
} from "Constants";
import Button from "components/common/atoms/Button";
import { Link, useLocation, useNavigate } from "react-router-dom";
import EnvelopIcon from "static/images/EnvelopIcon";
import PhoneCallIcon from "static/images/PhoneCall";
import HeartFillICon from "static/images/HeartFillICon";
import HeartIcon from "static/images/HeartIcon";
import MatchingIcon from "static/images/MatchingIcon";
import EditIcon from "static/images/EditIcon";
import ArchiveNew from "static/images/ArchiveNew";
import ResumeIcon from "static/images/ResumeIcon";
import FollowUpIcon from "static/images/FollowUpIcon";
import StartIcon from "static/images/StartIcon";
import {
  formatDate,
  formatDateAndTime,
  stopRecording,
} from "components/common/utlis/HelperFunctions";
import StopIcon from "static/images/StopIcon";
import ModalPopup from "components/common/atoms/ModalPopup";
import SalesNote from "components/CentralDataMangement/salesNote/SalesNoteModalPopups";
import {
  createQueryParams,
  filterAndMerge,
  getQueryParamsFromRedux,
} from "components/common/utlis/HelperFunctions";
import { createFilterConfig } from "./Filters";
import { useDispatch } from "react-redux";
import { RootState } from "store";
import {
  clearDestinationPath,
  setDestinationPath,
} from "features/NavigationSlice";
import SearchFields from "components/common/atoms/SearchFields";
import ResetBtn from "components/common/atoms/ResetBtn";
import AdvancedSearchBtn from "components/common/atoms/AdvancedSearchBtn";
import ActionIconWrapper from "components/common/atoms/ActionIconWrapper";
import Title from "components/common/atoms/Title";
import AdvancedSearch from "components/common/molecules/AdvancedSearch";
import NavBar from "components/common/atoms/NavBar";
import PaginationNew from "components/common/atoms/PaginationNew";
import Sorting from "components/common/atoms/Sorting";
import SpinnerWrapper from "components/common/atoms/SpinnerWrapper";
import TextEllipsis from "components/common/atoms/TextEllipsis";
import UserInfoTooltip from "components/common/atoms/UserInfoTooltip";
import NoRecords from "components/common/atoms/NoRecords";
import UnArchive from "static/images/UnArchive";

const ManageCandidates: React.FC = () => {
  //*********Manage candidates page start *****************************************************************************************************/

  const authUser = useSelector(selectAuth);
  const [loading, setLoading] = useState(true);

  const [dropdownList, setDropdownList] =
    useState<dropDownList>(intialDropDownList);
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [candidates, setCandidates] = useState<CandidateData[]>([]);
  const [paginationData, setPaginationData] = useState({
    totalPages: 1,
    totalRecords: 1,
    cursor: null,
  });
  const destinationPath = useSelector(
    (state: RootState) => state.navigation.destinationPath
  );
  const queryParams = getQueryParamsFromRedux(destinationPath);
  const filters = createFilterConfig(dropdownList);

  // Initialize search data based on query params
  const defaultSearchData: CandidateSearchData = intialCandidateSearchData;

  //advanced search
  const defaultAdvSearchData: candidateAdvSearchData =
    defaultCandidateAdvSearchData;

  const filteredSearchData = filterAndMerge(defaultSearchData, queryParams);
  const filteredAdvSearchData = filterAndMerge(
    defaultAdvSearchData,
    queryParams
  );
  //active tab status
  const defaultTabData = {
    tabFieldName: "candidateType",
    tabFieldValue: null,
  };
  const filteredTabData = filterAndMerge(defaultTabData, queryParams);

  const [activeTab, setActiveTab] = useState({
    ...defaultTabData,
    ...filteredTabData,
  });

  const navItems = [
    { label: "All", fieldName: "candidateType", value: null },
    { label: "Hotlist", fieldName: "hotlisted", value: 1 },
    { label: "Archived", fieldName: "archiveStatus", value: 3 },
  ];

  // Initialize search data based on query params, merging with default values
  const [searchData, setSearchData] = useState<CandidateSearchData>({
    ...defaultSearchData,
    ...filteredSearchData,
  });

  const [advSearchData, setAdvSearchData] = useState({
    ...defaultAdvSearchData,
    ...filteredAdvSearchData,
  });

  useEffect(() => {
    const filterData = { ...searchData, ...advSearchData, ...activeTab };
    const searchUrl = createQueryParams(filterData);
    dispatch(setDestinationPath(searchUrl));
    setLoading(true);
    const delayDebounceFn = setTimeout(() => {
      fetchDetails(filterData);
    }, 2000);
    return () => clearTimeout(delayDebounceFn);
  }, [searchData]);

  const fetchDetails = async (filterData: any) => {
    setLoading(true);
    const data = {
      search: filterData,
    };
    const response = await ApiCall.service(
      "getCandidates",
      "POST",
      data,
      false,
      "candidate-creation"
    );
    if (response.status === 200) {
      const { candidatesData, dropdownData, paginationData } = response.data;
      setCandidates(candidatesData);
      setPaginationData(paginationData);
      setDropdownList(dropdownData);
    }
    setLoading(false);
  };

  //** sorting */
  const handleOrderByColumn = (column: string) => {
    let newOrder: "" | "asc" | "desc" = ""; // Explicit type declaration
    let newColumn = column;

    if (searchData.orderByColumn === column) {
      // Cycle through the three possible order values: '', 'asc', 'desc'
      if (searchData.orderIn === "") {
        newOrder = "asc"; // From '' to 'asc'
      } else if (searchData.orderIn === "asc") {
        newOrder = "desc"; // From 'asc' to 'desc'
      } else {
        newOrder = ""; // From 'desc' to ''
        newColumn = ""; // Reset column if no order
      }
    } else {
      // If it's a new column, start with ascending
      newOrder = "asc";
    }

    setSearchData((prevSearch) => ({
      ...prevSearch,
      currentPage: 1, // Reset pagination on sort change
      orderByColumn: newColumn, // Set the new column or empty string
      orderIn: newOrder, // Set the new order (asc, desc, or empty)
    }));
  };

  //** change handlers */
  const handleSearchChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.target;
    setSearchData((prevSearch) => ({
      ...prevSearch,
      currentPage: 1,
      [name]: value,
    }));
  };

  const handlePageChange = (pageNumber: number) => {
    setSearchData((prevSearch) => ({
      ...prevSearch,
      currentPage: pageNumber,
    }));
  };

  const handleSelectChange = (selectedOption: any, fieldName: string) => {
    setSearchData((prevSearch) => ({
      ...prevSearch,
      currentPage: 1,
      [fieldName]: selectedOption,
    }));
  };

  const handleDateChange = (date: Date | null, fieldName: string) => {
    setSearchData((prevSearch) => ({
      ...prevSearch,
      currentPage: 1,
      [fieldName]: date,
    }));
  };

  const handleItemsPerPageChange = (newItemsPerPage: number) => {
    setSearchData((prev) => ({
      ...prev,
      recordsPerPage: newItemsPerPage,
      currentPage: 1,
    }));
  };

  const [searchModal, setSearchModal] = useState(false);

  const handleCloseSearchModal = () => {
    setSearchModal(false);
  };

  const handleSearchClick = () => {
    setSearchModal(true);
  };

  const handleSearchConfirm = () => {
    const filterData = { ...searchData, ...advSearchData };
    const searchUrl = createQueryParams(filterData);
    dispatch(setDestinationPath(searchUrl));
    fetchDetails(filterData); // Pass the new search data directly
  };

  const clearAdvSearchFilter = () => {
    setAdvSearchData(defaultAdvSearchData);
    const filterData = { ...searchData, ...defaultAdvSearchData };
    const searchUrl = createQueryParams(filterData);
    dispatch(setDestinationPath(searchUrl));
    fetchDetails(filterData);
  };

  const handleReset = async (e: React.FormEvent) => {
    dispatch(clearDestinationPath());
    setSearchData(defaultSearchData);
    setAdvSearchData(defaultAdvSearchData);
    setActiveTab(defaultTabData);
  };

  const handleNavItemClick = (tabFieldName: string, tabFieldValue: any) => {
    setSearchData((prev) => {
      const updatedState: any = { ...prev };
      updatedState[tabFieldName] = tabFieldValue;
      updatedState["currentPage"] = 1;

      navItems.forEach((item) => {
        if (item.fieldName !== tabFieldName) {
          updatedState[item.fieldName] = null;
        }
      });

      return updatedState;
    });
    setActiveTab({ tabFieldName, tabFieldValue });
  };

  // ****** Manage candidats page end**************************************************************************************************************************

  //&&&&&& FOLLOW UPS START *&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&/
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [sidebarWidth, setSidebarWidth] = useState(""); // State to store sidebar width

  const [entity, setEntity] = useState<FollowUpEntity>({
    entityId: null,
    isInBright: false,
  });

  const handleIconClick = (
    event: any,
    id: string | number | null,
    officeId: string | number | null,
    brightId: string | number | null
  ) => {
    event.preventDefault(); // Prevent the default link action
    if (entity.entityId === id) {
      setEntity({
        entityId: null,
        officeId: null,
        isInBright: false,
      });
      setIsSidebarOpen(false);
    } else {
      setEntity({
        entityId: id ?? null,
        officeId: officeId ?? null,
        isInBright: brightId !== null ? true : false,
      });
      setIsSidebarOpen(true);
    }
  };
  const handleCloseSidebar = () => {
    setIsSidebarOpen(false);
    setEntity({
      entityId: null,
      officeId: null,
      isInBright: false,
    });
  };

  const getWidth = (width: any) => {
    setSidebarWidth(width);
  };

  // &&&&&& FOLLOW UPS END &&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&

  // ###### HOTLIST START ########################################################################################################################################
  const submitHotlist = async () => {
    const converteCandidates = candidates.map(({ candidateId, ...rest }) => ({
      id: candidateId,
      ...rest,
    }));
    const data = {
      type: "candidate",
      data: converteCandidates,
    };
    const response = await ApiCall.service(
      "hotlist",
      "POST",
      data,
      false,
      CENTRAL_DATA_MANAGEMENT_MICROSERVICE
    );

    if (response.status === 200) {
      CustomNotify({ type: "success", message: t(response.msg) });
    } else if (response.status === 400) {
      CustomNotify({ type: "error", message: t(response.msg) });
    } else {
      CustomNotify({ type: "error", message: t("Something went wrong") });
    }
  };
  const handleHotlist = (
    candidateId: number | undefined,
    hotlisted: boolean | null
  ) => {
    const status = hotlisted == null || hotlisted == false ? true : false;

    const updatedCandidates = candidates.map((candidate) => {
      if (candidate.candidateId === candidateId && candidateId != undefined) {
        return { ...candidate, hotlisted: status };
      }
      return candidate;
    });

    setCandidates(updatedCandidates);
  };
  //#### HOTLIST END ##############################################################################################################################################

  //%%%%%%%%%%%%%%%% RESUME START %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%/
  const createResume = async (candidateId: number | null | undefined) => {
    if (candidateId) {
      const url = `createCandidateResume`;
      const data = {
        candidateId: candidateId,
      };
      const response = await ApiCall.service(
        url,
        "POST",
        data,
        true,
        CANDIDATE_MICROSERVICE
      );
      if (response.status === 200) {
        window.open(response.data.filePath, "_blank");
      }
    }
  };
  ///%%%%% RESUME END %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%

  //@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@ SALES NOTES START @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@*
  const [salesnote, setSalesNote] = useState({
    name: "",
    phone: "",
    id: null,
    navigate: "",
    activeRecordingId: null as number | null | undefined,
    triggerMeeting: false as boolean,
    type: null as number | null,
    prevId: null as number | null | undefined,
    isInBright: false as boolean,
  });

  useEffect(() => {
    if (candidates) {
      candidates.forEach((candidate) => {
        if (candidate.recordingStatus === 1) {
          setSalesNote((prev) => ({
            ...prev,
            activeRecordingId: candidate?.candidateId,
            prevId: candidate?.candidateId,
          }));
        }
      });
    }
  }, [candidates]);

  const handleMeeting = (
    name: string,
    phone: string,
    id: any,
    candidateId: any,
    brightId: number | null,
    navigate?: string
  ) => {
    setSalesNote({
      name: name,
      phone: phone,
      id: id,
      navigate: navigate ?? "",
      activeRecordingId: candidateId,
      triggerMeeting: true,
      type: 1, // For candidate type is 1,
      prevId: null,
      isInBright: brightId ? true : false,
    });
  };

  const stoprecording = async () => {
    await stopRecording(authUser.userId, setSalesNote);
  };

  const updateSalesNote = (updatedSalesNote: any) => {
    setSalesNote(updatedSalesNote);
  };
  //@@@ SALES NOTES END @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@

  //  !!!!!!!!!!!!!!! ARCHIVE START !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!//
  const [showModal, setShowModal] = useState(false);
  const [deleteID, setDeleteId] = useState<number | undefined>();
  const [modalDetails, setModalDetails] = useState({
    status: 0,
    title: '' as string,
    body: '' as string,
    closeTitle: '' as string,
    saveTitle: '' as string
  });

  const deleteUser = (id: number | undefined, status: number) => {
    setShowModal(true);
    setDeleteId(id);
    if (status === 1) {
      setModalDetails((prev) => ({
        ...prev,
        status: status,
        title: t("Unarchive candidate"),
        body: t("Are you sure want to unarchive") + "?",
        closeTitle: t('No'),
        saveTitle: t('Yes')
      }));
    } else {
      setModalDetails((prev) => ({
        ...prev,
        status: status,
        title: t("Archive confirmation"),
        body: t("Are you sure want to archive") + "?",
        closeTitle: t('No'),
        saveTitle: t('Yes')
      }));
    }
  };

  const handleModalClose = () => setShowModal(!showModal);

  const handleSaveChanges = async () => {
    setShowModal(false);
    if (deleteID) {
      const data = {
        status: modalDetails.status,
      };
      const response = await ApiCall.service(
        `archiveCandidate/${deleteID}`,
        "POST",
        data,
        false,
        "candidate-creation"
      );
      if (response.status === 200) {
        setCandidates((prevdata) =>
          prevdata.filter((candidate) => candidate.candidateId !== deleteID)
        );
        if (modalDetails.status === 3) {
          const url = `deleteUser/${response.userId}`;
          await ApiCall.getService(url, "GET", "identity-manager");
        } else {
          const url = `unarchiveUser/${response.userId}`;
          await ApiCall.getService(url, "GET", "identity-manager");
        }

        CustomNotify({ type: "success", message: t(response.msg) });
      }
    }
  };
  //  !!!!!!!!!!!!!!!!!!!!!!!!!!!!! ARCHIVE END !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!1***

  // **^^&^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^ EDIT OR VIEW POP UP START !!!!!!!!!!!!!!!!!!!!^^^^^^^^^^^^^^^^^^^^^^^^^^^^^
  const [canId, setCanId] = useState(null);

  const [editModal, setEditModal] = useState(false);

  const handleEdit = (canId: any) => {
    setCanId(canId);
    setEditModal(true);
  };

  const handleCloseModal = () => {
    setEditModal(false);
  };

  const handleConfirm = () => {
    setEditModal(false);
    navigate(`/candidate/edit/${canId}`);
  };

  const handleClose = () => {
    setEditModal(false);
    navigate(`/candidate/view/${canId}`);
  };

  // **^^&^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^ EDIT OR VIEW POP UP END !!!!!!!!!!!!!!!!!!!!^^^^^^^^^^^^^^^^^^^^^^^^^^^^^

  const navigateToScreening = (candidate: CandidateData) => {
    if (!candidate?.is3daysago && authUser.userId !== candidate?.consultantId) {
      const date = candidate?.created_at ? formatDate(new Date(candidate?.created_at)) : "";
      setShowModal(true);
      setModalDetails((prev) => ({
        ...prev,
        title: t("Action disabled"),
        body: `${candidate?.consultant} from Office ${candidate?.officename} is already working with the candidate.
         If you want to proceed, you can start working with the candidate after 3 days as of ${date}.`,
        closeTitle: '',
        saveTitle: ''
      }));
    } else {
      navigate(`/matching-list/candidate/${candidate.candidateId}`);
    }
  }

  return (
    <>
      <AccessControl
        requiredPermissions={[
          {
            permission: "Candidate",
            read: true,
          },
        ]}
        renderNoAccess={true}
      >
        {/* <div
          className={`main-container ${
            isSidebarOpen ? "sidebar-open" : ""
          } rightSidebar`}
          style={{ marginRight: isSidebarOpen ? sidebarWidth : undefined }}
        > */}
        <Title title={t("Candidates")} />
        <div className="search-bar">
          <div className="row">
            <div className="col-md-12 company-tabs">
              <NavBar
                navItems={navItems}
                onNavItemClick={handleNavItemClick}
                className="custom-navbar"
                activeItem={activeTab}
              />
            </div>
          </div>
        </div>
        <div className="position-relative tableMainWrapper">
          <div className="row">
            <div className="ManageCreateBtn">
              <AccessControl
                requiredPermissions={[
                  {
                    permission: "Candidate",
                    create: true,
                  },
                ]}
              >
                <LinkTo
                  pagelink="/candidate/create"
                  title={t("Create candidate")}
                  icon={faPlus}
                  className="me-3"
                />
              </AccessControl>
              <AccessControl
                requiredPermissions={[
                  {
                    permission: "Candidate",
                    update: true,
                  },
                ]}
              >
                {candidates && candidates.length > 0 && (
                  <div className="row">
                    <div className="col-12">
                      <Button
                        title={t("Update hotlist")}
                        className="form-button"
                        handleClick={submitHotlist}
                      />
                    </div>
                  </div>
                )}
              </AccessControl>
            </div>
          </div>
          <div className={`table-responsive tableSection`}>
            <table className="table manageCandidates border-0 mb-0">
              <thead>
                <tr className="tableSearchFields">
                  <SearchFields
                    searchData={searchData}
                    filters={filters}
                    handleSearchChange={handleSearchChange}
                    handleSelectChange={handleSelectChange}
                    handleDateChange={handleDateChange}
                  />
                  <th className="border-0">
                    <div className="d-flex align-items-center w-100">
                      <ResetBtn handleResetClick={handleReset} />
                      <AdvancedSearchBtn
                        handleSearch={handleSearchClick}
                        clearAdvSearchFilter={clearAdvSearchFilter}
                        filterData={advSearchData}
                      />
                    </div>
                  </th>
                </tr>
              </thead>
            </table>
            <div className="tableContainer manageCandidates scrollBarDesign">
              <table className="table table-hover">
                <thead>
                  <tr className="TableHeader">
                    <th
                      onClick={() => handleOrderByColumn("office")}
                      className="cursor-pointer"
                    >
                      {t("Office")}
                      <Sorting
                        sortType={
                          searchData.orderByColumn === "office"
                            ? searchData.orderIn
                            : ""
                        }
                      />
                    </th>
                    <th
                      onClick={() => handleOrderByColumn("firstName")}
                      className="cursor-pointer"
                    >
                      <div className="d-flex">
                        <TextEllipsis
                          title={t("First name")}
                          label={t("First name")}
                          width="5vw"
                        />
                        <Sorting
                          sortType={
                            searchData.orderByColumn === "firstName"
                              ? searchData.orderIn
                              : ""
                          }
                        />
                      </div>
                    </th>
                    <th
                      onClick={() => handleOrderByColumn("lastName")}
                      className="cursor-pointer"
                    >
                      <div className="d-flex">
                        <TextEllipsis
                          title={t("Last name")}
                          label={t("Last name")}
                          width="5vw"
                        />
                        <Sorting
                          sortType={
                            searchData.orderByColumn === "lastName"
                              ? searchData.orderIn
                              : ""
                          }
                        />
                      </div>
                    </th>
                    <th>{t("Consultant")} </th>
                    <th>{t("Street")} </th>
                    <th>
                      <TextEllipsis
                        title={t("Postal code")}
                        label={t("Postal code")}
                        width="6.5vw"
                      />
                    </th>
                    <th
                      onClick={() => handleOrderByColumn("email")}
                      className="cursor-pointer"
                    >
                      {t("Email")}
                      <Sorting
                        sortType={
                          searchData.orderByColumn === "email"
                            ? searchData.orderIn
                            : ""
                        }
                      />
                    </th>
                    <th>
                      <TextEllipsis
                        label={t("Phone number")}
                        title={t("Phone number")}
                        width="6.5vw"
                      />
                    </th>
                    <th>{t("Status")}</th>
                    <th>{t("Created at")}</th>
                    <AccessControl
                      requiredPermissions={[
                        {
                          permission: "Candidate",
                          update: true,
                          delete: true,
                        },
                      ]}
                      actions={true}
                      strict={false}
                    >
                      <th>{t("Action")} </th>
                    </AccessControl>
                  </tr>
                </thead>
                <tbody>
                  {loading ? (
                    <SpinnerWrapper headerLength={11} />
                  ) : (
                    <>
                      {isSidebarOpen && (
                        <RightSideBar
                          title={"Follow-ups"}
                          entityType={"candidate"}
                          officeId={entity.officeId}
                          entityId={entity.entityId}
                          isInBright={entity.isInBright}
                          onClose={handleCloseSidebar}
                          getWidth={getWidth}
                          recordsLimit={10}
                          className="right-sidebar"
                          destination="/manage-candidates"
                        />
                      )}
                      {candidates && candidates.length > 0 ? (
                        candidates.map((candidate, index) => {
                          let color =
                            candidate.status === 0
                              ? "bg-warning"
                              : candidate.status === 3
                                ? "bg-danger"
                                : "bg-success";
                          return (
                            <tr
                              key={candidate.candidateId}
                              className="align-middle"
                            >
                              <td
                                className="text-break "
                                data-label={t("Office")}
                              >
                                {candidate.officename === " - "
                                  ? "--"
                                  : candidate.officename}
                              </td>
                              <td
                                className="text-break"
                                data-label={t("First name")}
                              >
                                <Link
                                  to={`/candidate/corner/${candidate.candidateId}`}
                                  target=""
                                >
                                  <TextEllipsis
                                    title={candidate.firstName}
                                    label={candidate.firstName}
                                    width="5.5vw"
                                  />
                                </Link>
                              </td>
                              <td
                                className="text-break"
                                data-label={t("Last name")}
                              >
                                <Link
                                  to={`/candidate/corner/${candidate.candidateId}`}
                                  target=""
                                >
                                  <TextEllipsis
                                    title={candidate.lastName}
                                    label={candidate.lastName}
                                    width="5vw"
                                  />
                                </Link>
                              </td>
                              <td
                                className="text-break"
                                data-label={t("Consultant")}
                              >
                                <TextEllipsis
                                  title={candidate.consultant}
                                  label={candidate.consultant}
                                  width="5vw"
                                />
                              </td>
                              <td
                                className="text-break"
                                data-label={t("Street")}
                              >
                                <TextEllipsis
                                  title={candidate.street}
                                  label={candidate.street}
                                  width="5vw"
                                />
                              </td>
                              <td
                                className="text-break"
                                data-label={t("Postal code")}
                              >
                                {candidate.postalCode}
                              </td>
                              <td
                                className="text-break"
                                data-label={t("Email")}
                              >
                                <a
                                  href={`mailto:${candidate.email}`}
                                  target="_blank"
                                >
                                  <TextEllipsis
                                    title={candidate.email}
                                    label={candidate.email}
                                    width="11vw"
                                  />
                                </a>
                              </td>
                              <td
                                className="text-break"
                                data-label={t("Phone")}
                              >
                                <a
                                  onClick={() =>
                                    handleMeeting(
                                      candidate.name,
                                      candidate.phone,
                                      candidate.userId,
                                      candidate.candidateId,
                                      candidate.brightId,
                                      CALL_3CX
                                    )
                                  }
                                  className="cursor-pointer"
                                >
                                  {candidate.phone}
                                </a>
                              </td>

                              <td className="text-break">
                                <span
                                  className={`d-inline-block rounded-circle status-icon ${color}`}
                                  data-toggle="tooltip"
                                  title={t(
                                    `${CandidateStatus[candidate.status ?? 0]}`
                                  )}
                                  style={{ marginLeft: "1vw" }}
                                ></span>
                              </td>
                              <td className="text-break">
                                {candidate.created}
                              </td>
                              <AccessControl
                                requiredPermissions={[
                                  {
                                    permission: "Candidate",
                                    update: true,
                                    delete: true,
                                  },
                                ]}
                                actions={true}
                                strict={false}
                              >
                                <td className="table-action-icons">
                                  <ActionIconWrapper initialVisibleCount={4}>
                                    <AccessControl
                                      requiredPermissions={[
                                        {
                                          permission: "Follow-up",
                                          read: true,
                                        },
                                      ]}
                                    >
                                      <Link
                                        // to={`/manage/follow-up/candidate/${user.candidate_id}/${user.officeId}`}
                                        to={""}
                                        title={t("View follow ups")}
                                        onClick={(e) => {
                                          handleIconClick(
                                            e,
                                            candidate.candidateId ?? null,
                                            candidate.officeId ?? null,
                                            candidate.brightId ?? null
                                          );
                                        }}
                                      >
                                        <FollowUpIcon />
                                      </Link>
                                    </AccessControl>
                                    {candidate.postScreeningStatus === 1 && (
                                      <AccessControl
                                        requiredPermissions={[
                                          {
                                            permission: "Candidate",
                                            read: true,
                                          },
                                        ]}
                                      >
                                        <Link
                                          to={``}
                                          className="table-action-icons"
                                          title={t("Matching vacancies")}
                                          onClick={() => navigateToScreening(candidate)}

                                        >
                                          <MatchingIcon />
                                        </Link>
                                      </AccessControl>
                                    )}
                                    <span
                                      onClick={(e) =>
                                        handleHotlist(
                                          candidate.candidateId,
                                          candidate.hotlisted
                                        )
                                      }
                                      title={
                                        candidate.hotlisted != null &&
                                          candidate.hotlisted
                                          ? t("Remove from hotlist")
                                          : t("Add to hotlist")
                                      }
                                      className="cursor-pointer"
                                    >
                                      {candidate.hotlisted != null &&
                                        candidate.hotlisted ? (
                                        <HeartFillICon />
                                      ) : (
                                        <HeartIcon />
                                      )}
                                    </span>
                                    <Link
                                      onClick={() =>
                                        createResume(candidate.candidateId)
                                      }
                                      to=""
                                      title={t("View resume")}
                                    >
                                      <ResumeIcon />
                                    </Link>
                                    <AccessControl
                                      requiredPermissions={[
                                        {
                                          permission: "Candidate",
                                          update: true,
                                        },
                                      ]}
                                    >
                                      <Link
                                        to={""}
                                        title={t("Edit")}
                                        onClick={() =>
                                          handleEdit(candidate.candidateId)
                                        }
                                      >
                                        <EditIcon />
                                      </Link>
                                    </AccessControl>
                                    {candidate.status != 3 && (
                                      <AccessControl
                                        requiredPermissions={[
                                          {
                                            permission: "Candidate",
                                            delete: true,
                                          },
                                        ]}
                                      >
                                        <Link
                                          to=""
                                          onClick={() =>
                                            deleteUser(candidate.candidateId, 3)
                                          }
                                          title={t("Archive")}
                                        >
                                          <ArchiveNew />
                                        </Link>
                                      </AccessControl>
                                    )}
                                    {candidate.status == 3 && (
                                      <AccessControl
                                        requiredPermissions={[
                                          {
                                            permission: "Candidate",
                                            delete: true,
                                          },
                                        ]}
                                      >
                                        <Link
                                          to=""
                                          onClick={() =>
                                            deleteUser(candidate.candidateId, 1)
                                          }
                                          title={t("Unarchive")}
                                        >
                                          <UnArchive />
                                        </Link>
                                      </AccessControl>
                                    )}
                                    <AccessControl
                                      requiredPermissions={[
                                        {
                                          permission: "Sales note",
                                          read: true,
                                        },
                                      ]}
                                    >
                                      {salesnote.activeRecordingId ===
                                        candidate.candidateId && (
                                          <span
                                            className="cursor-pointer"
                                            onClick={() => stoprecording()}
                                            title={t("Stop")}
                                          >
                                            <StopIcon />
                                          </span>
                                        )}
                                      {salesnote.activeRecordingId === null && (
                                        <span
                                          className="cursor-pointer"
                                          onClick={() =>
                                            handleMeeting(
                                              candidate.name,
                                              candidate.phone,
                                              candidate.userId,
                                              candidate.candidateId,
                                              candidate.brightId
                                            )
                                          }
                                          title={t("Start")}
                                        >
                                          <StartIcon />
                                        </span>
                                      )}
                                    </AccessControl>
                                    <Link
                                      to={`mailto:${candidate.email}`}
                                      target="_blank"
                                      title={t("Email")}
                                    >
                                      <EnvelopIcon />
                                    </Link>
                                    <span
                                      onClick={() =>
                                        handleMeeting(
                                          candidate.name,
                                          candidate.phone,
                                          candidate.userId,
                                          candidate.candidateId,
                                          candidate.brightId,
                                          CALL_3CX
                                        )
                                      }
                                      title={t("Call")}
                                      className="cursor-pointer"
                                    >
                                      <PhoneCallIcon />
                                    </span>
                                    <div>
                                      <UserInfoTooltip
                                        createdBy={candidate.created_by}
                                        createdAt={formatDateAndTime(
                                          candidate.created_at
                                        )}
                                        updatedBy={candidate.updated_by}
                                        updatedAt={formatDateAndTime(
                                          candidate.updated_at
                                        )}
                                      />
                                    </div>
                                  </ActionIconWrapper>
                                </td>
                              </AccessControl>
                            </tr>
                          );
                        })
                      ) : (
                        <NoRecords headerLength={11} />
                      )}
                    </>
                  )}
                </tbody>
              </table>
            </div>
            {!loading && (
              <div className="pagination d-block">
                <PaginationNew
                  currentPage={searchData.currentPage}
                  totalPages={paginationData.totalPages}
                  totalRecords={paginationData.totalRecords}
                  recordsPerPage={searchData.recordsPerPage}
                  onItemsPerPageChange={handleItemsPerPageChange}
                  onPageChange={(newPage: any) => handlePageChange(newPage)}
                />
              </div>
            )}
          </div>
          <div className="col-md-6 align-self-center backPadding">
            <Link
              to={"/dashboard"}
              className=" back-btn text-decoration-underline"
            >
              {t("Back")}
            </Link>
          </div>

          <ModalPopup
            show={showModal}
            onHide={handleModalClose}
            title={modalDetails.title}
            body={modalDetails.body}
            onCloseButtonClick={handleModalClose}
            onConfirmButtonClick={handleSaveChanges}
            closeTitle={modalDetails.closeTitle}
            confirmTitle={modalDetails.saveTitle}
          />
          <AdvancedSearch
            show={searchModal}
            onHide={handleCloseSearchModal}
            onSearchConfirm={handleSearchConfirm}
            advSearchData={advSearchData}
            setAdvSearchData={setAdvSearchData}
            fieldConfigs={filters}
            clearAdvSearchFilter={clearAdvSearchFilter}
          />
          <ModalPopup
            show={editModal}
            onHide={handleCloseModal}
            title={t("Edit candidate")}
            body={t("Would you like to edit candidate") + "?"}
            onCloseButtonClick={handleClose}
            onConfirmButtonClick={handleConfirm}
            closeTitle={t("No")}
            confirmTitle={t("Yes")}
          />
        </div>
        {/* </div> */}
        <SalesNote salesnote={salesnote} setSalesNote={updateSalesNote} />
      </AccessControl>
    </>
  );
};
export default ManageCandidates;
