import React, { useState, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { CANDIDATE_CENTRAL, PHONE_CALL_URL } from "routes/ApiEndpoints";
import { ApiCall } from "components/common/services/ApiServices";
import Title from "components/common/atoms/Title";
import {
  t,
  translate,
} from "components/CentralDataMangement/translation/Translation";
import { CANDIDATE_MICROSERVICE, COMPANY_MICROSERVICE } from "Constants";
import EnvelopIcon from "static/images/EnvelopIcon";
import PhoneCallIcon from "static/images/PhoneCall";
import { Spinner } from "react-bootstrap";
import ClappingHands from "static/images/ClappingHands";
import HandsPraying from "static/images/HandsPraying";
import CentralStarIcon from "static/images/CentralStarIcon";
import CentralCheckIcon from "static/images/CentralCheckIcon";
import MicrophoneIcon2 from "static/images/MicrophoneIcon2";
import AccessControl from "components/common/services/RolesAndPermissions/AccessControl";
import {
  CandidateCentralData,
  CandidateCentralSearchData,
  dropDownList,
  intialCandidateCentralSearchData,
  intialDropDownList,
} from "./Interface";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store";
import {
  createQueryParams,
  filterAndMerge,
  getQueryParamsFromRedux,
} from "components/common/utlis/HelperFunctions";
import { createFilterConfig } from "./Filters";
import {
  clearDestinationPath,
  setDestinationPath,
} from "features/NavigationSlice";
import NavBar from "components/common/atoms/NavBar";
import ResetBtn from "components/common/atoms/ResetBtn";
import SearchFields from "components/common/atoms/SearchFields";
import PaginationNew from "components/common/atoms/PaginationNew";
import Sorting from "components/common/atoms/Sorting";
import SpinnerWrapper from "components/common/atoms/SpinnerWrapper";
import TextEllipsis from "components/common/atoms/TextEllipsis";
import NoRecords from "components/common/atoms/NoRecords";
import ResumeIcon from "static/images/ResumeIcon";

const CandidateCentral: React.FC = () => {
  const [candidates, setCandidates] = useState<CandidateCentralData[]>([]);
  const [loading, setLoading] = useState(true);
  const [showAllFunctions, setShowAllFunctions] = useState<boolean[]>(
    Array(candidates.length).fill(false)
  );
  const [dropdownList, setDropdownList] =
    useState<dropDownList>(intialDropDownList);
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [paginationData, setPaginationData] = useState({
    totalPages: 1,
    totalRecords: 1,
    cursor: null,
  });
  const destinationPath = useSelector(
    (state: RootState) => state.navigation.destinationPath
  );
  const queryParams = getQueryParamsFromRedux(destinationPath);
  const filters = createFilterConfig(dropdownList);

  // Initialize search data based on query params
  const defaultSearchData: CandidateCentralSearchData =
    intialCandidateCentralSearchData;

  //advanced search
  const defaultAdvSearchData = {};

  const filteredSearchData = filterAndMerge(defaultSearchData, queryParams);
  const filteredAdvSearchData = filterAndMerge(
    defaultAdvSearchData,
    queryParams
  );

  //active tab status
  const defaultTabData = {
    tabFieldName: "candidateType",
    tabFieldValue: null,
  };
  const filteredTabData = filterAndMerge(defaultTabData, queryParams);

  const [activeTab, setActiveTab] = useState({
    ...defaultTabData,
    ...filteredTabData,
  });

  const navItems = [
    { label: "All", fieldName: "candidateType", value: null },
    { label: "Hotlist", fieldName: "hotlisted", value: 1 },
  ];

  // Initialize search data based on query params, merging with default values
  const [searchData, setSearchData] = useState<CandidateCentralSearchData>({
    ...defaultSearchData,
    ...filteredSearchData,
  });

  const [advSearchData, setAdvSearchData] = useState({
    ...defaultAdvSearchData,
    ...filteredAdvSearchData,
  });

  useEffect(() => {
    const filterData = { ...searchData, ...advSearchData, ...activeTab };
    const searchUrl = createQueryParams(filterData);
    dispatch(setDestinationPath(searchUrl));
    setLoading(true);
    const delayDebounceFn = setTimeout(() => {
      fetchDetails(filterData);
    }, 2000);
    return () => clearTimeout(delayDebounceFn);
  }, [searchData]);

  const fetchDetails = async (filterData: any) => {
    setLoading(true);
    const data = {
      search: filterData,
    };
    const response = await ApiCall.service(
      CANDIDATE_CENTRAL,
      "POST",
      data,
      false,
      CANDIDATE_MICROSERVICE
    );
    if (response.status === 200) {
      const { candidateData, dropdownData, paginationData } = response.data;
      setCandidates(candidateData);
      setPaginationData(paginationData);
      setDropdownList(dropdownData);
    }
    setLoading(false);
  };

  //** sorting */
  const handleOrderByColumn = (column: string) => {
    let newOrder: "" | "asc" | "desc" = ""; // Explicit type declaration
    let newColumn = column;

    if (searchData.orderByColumn === column) {
      // Cycle through the three possible order values: '', 'asc', 'desc'
      if (searchData.orderIn === "") {
        newOrder = "asc"; // From '' to 'asc'
      } else if (searchData.orderIn === "asc") {
        newOrder = "desc"; // From 'asc' to 'desc'
      } else {
        newOrder = ""; // From 'desc' to ''
        newColumn = ""; // Reset column if no order
      }
    } else {
      // If it's a new column, start with ascending
      newOrder = "asc";
    }

    setSearchData((prevSearch) => ({
      ...prevSearch,
      currentPage: 1, // Reset pagination on sort change
      orderByColumn: newColumn, // Set the new column or empty string
      orderIn: newOrder, // Set the new order (asc, desc, or empty)
    }));
  };

  //** change handlers */
  const handleSearchChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.target;
    setSearchData((prevSearch) => ({
      ...prevSearch,
      currentPage: 1,
      [name]: value,
    }));
  };

  const handlePageChange = (pageNumber: number) => {
    setSearchData((prevSearch) => ({
      ...prevSearch,
      currentPage: pageNumber,
    }));
  };

  const handleSelectChange = (selectedOption: any, fieldName: string) => {
    setSearchData((prevSearch) => ({
      ...prevSearch,
      currentPage: 1,
      [fieldName]: selectedOption,
    }));
  };

  const handleDateChange = (date: Date | null, fieldName: string) => {
    setSearchData((prevSearch) => ({
      ...prevSearch,
      currentPage: 1,
      [fieldName]: date,
    }));
  };

  const handleItemsPerPageChange = (newItemsPerPage: number) => {
    setSearchData((prev) => ({
      ...prev,
      recordsPerPage: newItemsPerPage,
      currentPage: 1,
    }));
  };

  const [searchModal, setSearchModal] = useState(false);

  // const handleCloseSearchModal = () => {
  //   setSearchModal(false);
  // };

  // const handleSearchClick = () => {
  //   setSearchModal(true);
  // };

  // const handleSearchConfirm = () => {
  //   const filterData = { ...searchData, ...advSearchData };
  //   const searchUrl = createQueryParams(filterData);
  //   dispatch(setDestinationPath(searchUrl));
  //   fetchDetails(filterData); // Pass the new search data directly
  // };

  // const clearAdvSearchFilter = () => {
  //   setAdvSearchData(defaultAdvSearchData);
  //   const filterData = { ...searchData, ...defaultAdvSearchData };
  //   const searchUrl = createQueryParams(filterData);
  //   dispatch(setDestinationPath(searchUrl));
  //   fetchDetails(filterData);
  // };

  const handleReset = async (e: React.FormEvent) => {
    dispatch(clearDestinationPath());
    setSearchData(defaultSearchData);
    setAdvSearchData(defaultAdvSearchData);
    setActiveTab(defaultTabData);
  };

  const handleNavItemClick = (tabFieldName: string, tabFieldValue: any) => {
    setSearchData((prev) => {
      const updatedState: any = { ...prev };
      updatedState[tabFieldName] = tabFieldValue;
      updatedState["currentPage"] = 1;

      navItems.forEach((item) => {
        if (item.fieldName !== tabFieldName) {
          updatedState[item.fieldName] = null;
        }
      });

      return updatedState;
    });
    setActiveTab({ tabFieldName, tabFieldValue });
  };

  const toggleShowAllFunctions = (index: number) => {
    setShowAllFunctions((prev) => {
      const updatedShowAllFunctions = [...prev];
      updatedShowAllFunctions[index] = !updatedShowAllFunctions[index];
      return updatedShowAllFunctions;
    });
  };

  useEffect(() => {
    // Initialize showAllFunctions state with false for each candidate
    setShowAllFunctions(Array(candidates.length).fill(false));
  }, [candidates]);
  const headers = Array(5).fill(null);

  //%%%%%%%%%%%%%%%% RESUME START %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%/
  const createResume = async (candidateId: number | null | undefined) => {
    if (candidateId) {
      const url = `createCandidateResume`;
      const data = {
        candidateId: candidateId,
      };
      const response = await ApiCall.service(
        url,
        "POST",
        data,
        true,
        CANDIDATE_MICROSERVICE
      );
      if (response.status === 200) {
        window.open(response.data.filePath, "_blank");
      }
    }
  };
  ///%%%%% RESUME END %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%

  return (
    <AccessControl
      requiredPermissions={[
        {
          permission: "Candidate central",
          read: true,
        },
      ]}
      renderNoAccess={true}
    >
      <Title title={t("Candidate central")} />
      <div className="search-bar paddingBottom1">
        <div className="row">
          <div className="col-md-12 company-tabs">
            <NavBar
              navItems={navItems}
              onNavItemClick={handleNavItemClick}
              className="custom-navbar"
              activeItem={activeTab}
            />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <div
            className="table-responsive tableSection"
            style={{ paddingTop: "0.5vw" }}
          >
            <table className="table candidateCentralTable border-0 mb-0">
              <thead>
                <tr className="tableSearchFields">
                  <SearchFields
                    searchData={searchData}
                    filters={filters}
                    handleSearchChange={handleSearchChange}
                    handleSelectChange={handleSelectChange}
                    handleDateChange={handleDateChange}
                  />
                  {headers.map((_, index) => (
                    <th key={index} className="border-0" />
                  ))}
                  <th className="border-0">
                    <div className="d-flex align-items-center w-100">
                      <ResetBtn handleResetClick={handleReset} />
                      {/* <AdvancedSearchBtn
                        handleSearch={handleSearchClick}
                        clearAdvSearchFilter={clearAdvSearchFilter}
                        filterData={advSearchData}
                      /> */}
                    </div>
                  </th>
                </tr>
              </thead>
            </table>
            <div className="tableContainer scrollBarDesign">
              <table className="table table-hover candidateCentralTable">
                <thead>
                  <tr className="TableHeader">
                    <th onClick={() => handleOrderByColumn("office")}>
                      {t("Office")}
                      <Sorting
                        sortType={
                          searchData.orderByColumn === "office"
                            ? searchData.orderIn
                            : ""
                        }
                      />
                    </th>
                    <th onClick={() => handleOrderByColumn("firstName")}>
                      {t("First name")}
                      <Sorting
                        sortType={
                          searchData.orderByColumn === "firstName"
                            ? searchData.orderIn
                            : ""
                        }
                      />
                    </th>
                    <th onClick={() => handleOrderByColumn("lastName")}>
                      {t("Last name")}
                      <Sorting
                        sortType={
                          searchData.orderByColumn === "lastName"
                            ? searchData.orderIn
                            : ""
                        }
                      />
                    </th>
                    <th className="align-middle">{t("Consultant")}</th>
                    <th className="align-middle">{t("Function")}</th>
                    <th className="align-middle">{t("Created at")}</th>
                    <th className="align-middle centralIcon text-center">
                      <span className="me-2">
                        <CentralCheckIcon />
                      </span>
                      {t("S")}
                    </th>
                    <th className="align-middle centralIcon text-center">
                      <span className="me-2">
                        <CentralStarIcon />
                      </span>
                      {t("P")}
                    </th>
                    <th className="align-middle centralIcon text-center">
                      <span className="me-2">
                        <MicrophoneIcon2 />
                      </span>
                      {t("I")}
                    </th>
                    <th className="align-middle centralIcon text-center">
                      <span className="me-2">
                        <HandsPraying />
                      </span>
                      {t("A")}
                    </th>
                    <th className="align-middle centralIcon text-center">
                      <span className="me-2">
                        <ClappingHands />
                      </span>
                      {t("C")}
                    </th>
                    <th>{t("Actions")}</th>
                  </tr>
                </thead>
                <tbody>
                  {loading ? (
                    <SpinnerWrapper headerLength={13} />
                  ) : (
                    <>
                      {candidates && candidates.length > 0 ? (
                        candidates.map((item, index) => (
                          <tr
                            key={index}
                            className="border-bottom mb-3 box-shadow align-middle"
                          >
                            <td
                              className="text-break "
                              data-label={t("Office")}
                            >
                              {item.officename === " - "
                                ? "--"
                                : item.officename}
                            </td>
                            <td
                              className="align-middle"
                              data-label={t("Company")}
                            >
                              <Link
                                to={`/candidate/corner/${item.id}?destination=/candidate-central`}
                                className="btn p-0 border-0 text-start"
                                target=""
                              >
                                {item.firstName}
                              </Link>
                            </td>
                            <td
                              className="align-middle"
                              data-label={t("Company")}
                            >
                              <Link
                                to={`/candidate/corner/${item.id}?destination=/candidate-central`}
                                className="btn p-0 border-0 text-start"
                                target=""
                              >
                                {item.lastName}
                              </Link>
                            </td>
                            <td
                              className="align-middle"
                              data-label={t("Consultant")}
                            >
                              {item.consultant}
                            </td>
                            <td className="align-middle moreLink">
                              {item.functions && item.functions.length > 0 ? (
                                <>
                                  {item.functions.length > 1 &&
                                  showAllFunctions[index] ? (
                                    item.functions.map((func, funcIndex) => (
                                      <div key={funcIndex}>
                                        <TextEllipsis
                                          label={String(func)}
                                          title={String(func)}
                                          width="10.5vw"
                                        />
                                      </div>
                                    ))
                                  ) : (
                                    <div>
                                      {item.functions[0] != null ? (
                                        <TextEllipsis
                                          label={t(
                                            `${String(item.functions[0])}`
                                          )}
                                          title={t(
                                            `${String(item.functions[0])}`
                                          )}
                                          width="10.5vw"
                                        />
                                      ) : (
                                        "--"
                                      )}
                                    </div>
                                  )}
                                  {item.functions &&
                                  item.functions.length > 1 ? (
                                    <span
                                      onClick={() =>
                                        toggleShowAllFunctions(index)
                                      }
                                      className="link-color cursor-pointer"
                                    >
                                      {showAllFunctions[index]
                                        ? t("View less")
                                        : t("View more")}
                                    </span>
                                  ) : (
                                    ""
                                  )}
                                </>
                              ) : (
                                "--"
                              )}
                            </td>
                            <td
                              data-label={t("ScreeningCount")}
                              className="align-middle"
                            >
                              {item.created}
                            </td>
                            <td
                              data-label={t("ScreeningCount")}
                              className="align-middle text-center"
                            >
                              {item.ScreeningCount}
                            </td>
                            <td
                              data-label={t("PresentedCount")}
                              className="align-middle text-center"
                            >
                              {item.PresentedCount}
                            </td>
                            <td
                              data-label={t("InterviewCount")}
                              className="align-middle text-center"
                            >
                              {item.InterviewCount}
                            </td>
                            <td
                              data-label={t("WageCount")}
                              className="align-middle text-center"
                            >
                              {item.WageCount}
                            </td>
                            <td
                              data-label={t("ContractCount")}
                              className="align-middle text-center"
                            >
                              {item.StarterCount}
                            </td>
                            <td className="table-action-icons px-2 align-middle">
                              <div className="d-none d-md-none d-lg-block">
                                <div className="pb-2 d-inline-block me-2">
                                  <a
                                    href={`mailto:${item.email}`}
                                    target="_blank"
                                    title={t("Email")}
                                    className="text-decoration-none"
                                  >
                                    <EnvelopIcon />
                                  </a>
                                </div>
                                <a
                                  href={
                                    item.phone !== null && item.phone !== ""
                                      ? `${PHONE_CALL_URL}${item.phone.replace(
                                          "+",
                                          "%2B"
                                        )}`
                                      : ""
                                  }
                                  target="_blank"
                                  title={t("Call")}
                                  className="text-decoration-none me-2"
                                >
                                  <PhoneCallIcon />
                                </a>
                                <Link
                                  onClick={() => createResume(item.id)}
                                  to=""
                                  title={t("View resume")}
                                >
                                  <ResumeIcon />
                                </Link>
                              </div>
                            </td>
                          </tr>
                        ))
                      ) : (
                        <NoRecords headerLength={8} />
                      )}
                    </>
                  )}
                </tbody>
              </table>
            </div>
            <div className="pagination d-block">
              <PaginationNew
                currentPage={searchData.currentPage}
                totalPages={paginationData.totalPages}
                totalRecords={paginationData.totalRecords}
                recordsPerPage={searchData.recordsPerPage}
                onItemsPerPageChange={handleItemsPerPageChange}
                onPageChange={(newPage: any) => handlePageChange(newPage)}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="row backPadding">
        <div className="col-md-6 align-self-center">
          <Link to="/dashboard" className="back-btn text-decoration-underline">
            {t("Back")}
          </Link>
        </div>
      </div>
    </AccessControl>
  );
};

export default translate(CandidateCentral);
