import { Fragment, useEffect, useState } from "react";
import { t, translate } from "components/CentralDataMangement/translation/Translation";
import { ApiCall } from "components/common/services/ApiServices";
import CustomNotify from "components/common/atoms/CustomNotify";
import { Link, useNavigate } from "react-router-dom";
import CardTitle from "components/common/atoms/CardTitle";
import { Tab, Tabs } from "react-bootstrap";
import InfoIcon from "static/images/InfoIcon";
import PickUp from "static/images/PickUp";
import Button from "components/common/atoms/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import RightArrow from "static/images/RightArrow";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";
import DashboardImage from "../DashboardImage";
import AssignTodoModal, { ModalData } from "components/CentralDataMangement/todos/AssignTodoModal";
import AssignTo from "static/images/AssignTo";
import { mapToSelect } from "components/common/utlis/MapToSelect";
import { FollowUpData } from "../Dashboard";
import FollowUpTable from "./FollowUpTable";
import GetCrudPermissions from "components/common/services/RolesAndPermissions/GetCrudPermissions";
import { Option } from "components/common/utlis/TypeAnnotations";
import { CANDIDATE_ROLE } from "Constants";
import ModalPopup from "components/common/atoms/ModalPopup";
import { addUserDetails } from "features/auth/AuthSlice";
import { useDispatch } from "react-redux";
import { ACCEPT_TERMS_AND_CONDITIONS } from "routes/ApiEndpoints";

const TodoDashboard: React.FC<{ params: any, followUpData: FollowUpData, handleFollowUpClose: (id: string) => void, newVacancies: number, newCandidates: number, office: Option | undefined }> = ({ params, followUpData, handleFollowUpClose, newVacancies, newCandidates, office = { value: null, label: "" } }) => {
  const { userAuth, CONST } = params;
  const [key, setKey] = useState("home"); // Initialize 'key' with a default value
  const [newTodos, setNewTodos] = useState([
    {
      id: null,
      candidateName: "",
      createdAt: "",
      todoId: null,
      stage: "",
      candidateId: null,
      stageId: null,
      assignedBy: "",
      assignedTo: "",
      assignedToId: "",
      companyId: null,
      vacancyId: null,
      function: "",
      presentationId: null,
      entity_id: null,
      link: "",
      description: ""
    },
  ]);
  const fetchTodoDetails = async (formData = {}, page = 1) => {
    const data = {
      formData: formData,
      page: page,
      userId: userAuth.userId,
      userRole: userAuth.role,
      type: "mytodos",
      template: "dashboard",
    };
    const stage = 1;
    const response = await ApiCall.service(
      `getTodos/${stage}`,
      "POST",
      data,
      false,
      CONST.CENTRAL_DATA_MANAGEMENT_MICROSERVICE
    );
    if (response.status === 200) {
      const newTodos = response.data[0];
      if (
        userAuth.role === CANDIDATE_ROLE &&
        response?.data?.["termsAndConditions"]
      ) {
        setShowTermsModal(true);
        setModal(response?.data["termsAndConditions"]);
      } else {
        if (userAuth.isSignature === false && userAuth.role === CANDIDATE_ROLE) {
          setShowSignatureModal(true);
        }
      }
      setNewTodos(newTodos);
      setModalData((prevData) => ({
        ...prevData,
        officeDropdown: mapToSelect(response.data['officesList'])
      }));
    }
  };
  const handleAssignTodoSuccess = () => {
    fetchTodoDetails();
  };

  const assignTodo = async (
    event: any,
    todoId: number | null,
    action: string,
    stageId: number | null,
    candidateId: number | null
  ) => {
    const data = {
      todoId: todoId,
      userId: userAuth.userId,
      stageId: stageId,
      actionType: action,
      candidateId: candidateId
    };

    if (action === "pickUpTodo") {
      const response = await ApiCall.service(
        "assignTodo",
        "POST",
        data,
        false,
        CONST.CENTRAL_DATA_MANAGEMENT_MICROSERVICE
      );
      if (response.status === 200) {
        fetchTodoDetails(); //To remove assigned todos from newTodos Tab
        CustomNotify({ type: "success", message: response.msg });
        handleAssignTodoSuccess();
      }
    }
  };

  useEffect(() => {
    fetchTodoDetails();
  }, []);

  const getLinkForStage = (
    todoExtensionId: number | null,
    stageId: number | null,
    todoId: number | null,
    candidateId: number | null,
    companyId: number | null,
    vacancyId: number | null,
    functionName: string,
    presentationId: number | null,
    role = userAuth.role
  ): string => {
    let link = "/todos/overview";

    switch (stageId) {
      case 1:
        link = `/candidate/edit/${candidateId}`;
        break;
      case 2:
        link = `/screening/${candidateId}`;
        break;
      case 3:
        link = `/matching-list/candidate/${candidateId}`;
        break;
      case 4:
        link = `/todos/company-pre-interview/${candidateId}/${companyId}/${todoExtensionId}/${todoId}/${functionName}/${presentationId}/${vacancyId}`;
        break;
      case 5:
        link = `/todos/candidate-pre-interview/${candidateId}/${companyId}/${todoExtensionId}/${todoId}/${functionName}/${presentationId}`;
        break;
      case 6:
        link = `/todos/pre-interview/${candidateId}/${companyId}/${todoExtensionId}/${todoId}/${functionName}/${vacancyId}/${presentationId}`;
        break;
      case 7:
        link = `/todos/interview-feedback/${candidateId}/${companyId}/${todoExtensionId}/${todoId}/${functionName}/${presentationId}`;
        break;
      case 8:
        link = `/todos/wage-discussion/${candidateId}/${companyId}/${todoExtensionId}/${todoId}/${vacancyId}/${functionName}/${presentationId}`;
        break;
      case 9:
        link = `/todos/contract-info-page/${candidateId}/${todoExtensionId}/${todoId}/${presentationId}/${functionName}/${companyId}/${stageId}`;
        break;
      case 10:
        link = `/todos/contract/${candidateId}/${todoExtensionId}/${todoId}/${presentationId}/${functionName}/${companyId}/${stageId}`;
        break;
      default:
        link = `/todos/overview`;
    }
    return link;
  };


  // For assign to modal popup
  const [isAssignTodoModalOpen, setAssignTodoModalOpen] = useState(false);
  const [todoId, setTodoId] = useState<any>();
  const [todoExtensionId, setTodoExtensionId] = useState<any>();
  const [type, setType] = useState("");

  const [modalData, setModalData] = useState<ModalData>({
    officeDropdown: [],
    stageId: null,
    candidateId: null,
  });


  const openAssignTodoModal = (event: any, todoId: number | null, todoExtensionId: number | null, stageId: number | null, candidateId: number | null) => {
    event.preventDefault();
    if (stageId === null) {
      setType("newtodos");
    } else {
      setType("myTodos");
    }
    setTodoId(todoId);
    setTodoExtensionId(todoExtensionId);
    setModalData((prevData) => ({
      ...prevData,
      stageId: stageId,
      candidateId: candidateId,
    }));
    setAssignTodoModalOpen(true);
  };

  const closeAssignTodoModal: any = () => {
    setAssignTodoModalOpen(false);
  };

  const canFollowUp = GetCrudPermissions("Follow-up");


  //Remainder for adding signature whenever a user login untill he adds his signature
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showSignatureModal, setShowSignatureModal] = useState(false);

  const handleSignatureModalClose = () => {
    setShowSignatureModal(false);
    dispatch(addUserDetails({ isSignature: true }));
  };

  const handleSignatureModal = () => {
    setShowSignatureModal(false);
    navigate("/add/signature");
    dispatch(addUserDetails({ isSignature: true }));
  };

  //for candidate accept terms modal pop up
  const [showTermsModal, setShowTermsModal] = useState(false);
  const [modal, setModal] = useState({
    body: "",
    title: "",
  });

  const handleSaveChanges = async () => {
    setShowTermsModal(false);
    setShowSignatureModal(true);
    const data = {
      termsAccepted: true,
      candidateId: userAuth.candidateId,
    };
    const response = await ApiCall.service(
      ACCEPT_TERMS_AND_CONDITIONS,
      "POST",
      data,
      true,
      CONST.CANDIDATE_MICROSERVICE
    );
  };

  return (
    <div className="row pb-4">
      <DashboardImage
        newVacancies={newVacancies}
        newCandidates={newCandidates}
        officeId={office?.value}
      />
      <div className="col-md-6 tasks-meeting-section">
        <div className="card position-relative h-100 rounded-3" style={{ padding: "1vw" }}>
          <div className="mb-2 pb-xxl-3">
            <CardTitle
              title={t("Time to work some magic!")}
              className={`ms-2 me-4 recruitment-card-num`}
            />
          </div>
          <Tabs
            id="controlled-tab-example"
            activeKey={key || "home"} // Perform a null check here
            onSelect={(k) => {
              if (k) {
                setKey(k); // Set the key only if it's a valid string
              }
            }}
            className="mb-3 dashboard-tasks-meeting-tabs border--bottom-tabs"
          >
            <Tab
              eventKey="home"
              title={
                <span className="position-relative recruitment-card-title">
                  {t("Tasks")}
                  {/* <Badge pill className="position-absolute">
                    0
                  </Badge> */}
                </span>
              }
            >
              <div className="dashboard-work-table">
                <table className="table table-borderless">
                  <tbody>
                    <>
                      {newTodos && newTodos.length > 0 ? (
                        newTodos.map((newTodo, index) => (
                          <Fragment key={`todos-${index}`}>
                            <>
                              {userAuth.role === CANDIDATE_ROLE ?
                                <>
                                  <tr
                                    key={`todo-${index}`}
                                    className="mb-2 box-shadow align-middle"
                                  >
                                    <td
                                      key={`td-${index}`}
                                      className="ps-lg-4"
                                      data-label={t("Candidate")}
                                    >
                                      {newTodo.description}
                                    </td>

                                    <td
                                      className="table-action-icons"
                                      key={`registered-${index}`}
                                    >
                                      <Link key={`link2${index}`}
                                        to={`${newTodo.link}/${newTodo.entity_id}`}
                                        state={{ origin: "dashboard" }}
                                      >
                                        <span
                                          key={`proceed-${index}`}
                                          data-bs-toggle="tooltip"
                                          title={t("Proceed")}
                                        >
                                          <RightArrow width={"2vw"} height={"2vw"} />
                                        </span>
                                      </Link>

                                    </td>
                                  </tr>

                                </>
                                :
                                <>
                                  {(newTodo.assignedBy === null) && (
                                    <tr
                                      key={`todo-${index}`}
                                      className="mb-2 box-shadow align-middle"
                                    >
                                      <td
                                        key={`td-${index}`}
                                        className="ps-lg-4"
                                        data-label={t("Candidate")}
                                      >
                                        {newTodo.candidateName}
                                      </td>
                                      <td key={`new-${index}`}>
                                        {t(`Newly registered`)}
                                      </td>
                                      <td
                                        className="table-action-icons"
                                        key={`registered-${index}`}
                                      >
                                        <Link
                                          to=""
                                        // className="todo-info-icon"
                                        >
                                          <span
                                            data-bs-toggle="tooltip"
                                            title={`${t("Registered on")} ${newTodo.createdAt}`}
                                            className="me-2"
                                          >
                                            <InfoIcon />
                                          </span>
                                        </Link>
                                        {userAuth.role !== CONST.AREA_MANAGER && userAuth.role !== CONST.OFFICE_MANAGER &&
                                          <Button
                                            handleClick={(event: any) =>
                                              assignTodo(
                                                event,
                                                newTodo.todoId,
                                                "pickUpTodo",
                                                1,
                                                newTodo.candidateId
                                              )}
                                            className=" border-0 bg-transparent me-2 px-0"
                                            toolTipName={t("Pick up")}
                                          >
                                            <PickUp />
                                          </Button>
                                        }
                                        {userAuth.role !== "RECRUITER" && userAuth.role !== "CANDIDATE" && userAuth.role !== "EMPLOYER" && (
                                          <>
                                            <Button
                                              handleClick={(event: any) => openAssignTodoModal(event, newTodo.todoId, newTodo.id, newTodo.stageId, newTodo.candidateId,)}
                                              className=" border-0 bg-transparent me-2 px-0"
                                              toolTipName={t("Transfer to")}
                                            >
                                              <AssignTo />
                                            </Button>
                                          </>
                                        )}
                                      </td>
                                    </tr>
                                  )}
                                  {newTodo.assignedBy !== null && (
                                    <tr
                                      key={`assigned-${index}`}
                                      className="mb-3 box-shadow align-middle"
                                    >
                                      <td
                                        key={`candidate-${index}`}
                                        className="ps-lg-4"
                                        data-label={t("Candidate")}
                                      >
                                        {newTodo.candidateName}
                                      </td>
                                      <td key={`stage-${index}`}>
                                        {t(`${newTodo.stage}`)}
                                      </td>
                                      <td key={`assgn-${index}`} className="align-middle table-action-icons">
                                        <div>
                                          <Link key={`link${index}`} to="">
                                            <span
                                              data-bs-toggle="tooltip"
                                              data-bs-placement="left"
                                              title={`${t(" Assigned by :")} ${newTodo.assignedBy
                                                } \n ${t("Assigned to :")} ${newTodo.assignedTo
                                                }`}
                                              className="me-2"
                                            >
                                              <InfoIcon />
                                            </span>
                                          </Link>
                                          {((newTodo.stageId === 19 || newTodo.stageId === 20) && userAuth.role !== CONST.AREA_MANAGER && userAuth.role !== CONST.OFFICE_MANAGER) &&
                                            <Button
                                              handleClick={(event: any) =>
                                                assignTodo(
                                                  event,
                                                  newTodo.id,
                                                  "pickUpTodo",
                                                  newTodo.stageId,
                                                  newTodo.candidateId
                                                )
                                              }
                                              className=" border-0 bg-transparent marginRightPoint5 px-0"
                                              toolTipName={t("Pick up")}
                                            >
                                              <PickUp />
                                            </Button>
                                          }
                                          {((newTodo.stageId === 19 || newTodo.stageId === 20) && userAuth.role !== "RECRUITER" && userAuth.role !== "CANDIDATE" && userAuth.role !== "EMPLOYER") && (
                                            <>
                                              <Button
                                                handleClick={(event: any) => openAssignTodoModal(event, newTodo.todoId, newTodo.id, newTodo.stageId, newTodo.candidateId,)}
                                                className=" border-0 bg-transparent marginRightPoint5 px-0"
                                                toolTipName={t("Transfer to")}
                                              >
                                                <AssignTo />
                                              </Button>
                                            </>
                                          )}
                                          {newTodo.assignedToId && newTodo.stageId !== 11 && (
                                            <Link key={`link2${index}`}
                                              to={getLinkForStage(
                                                newTodo.id,
                                                newTodo.stageId,
                                                newTodo.todoId,
                                                newTodo.candidateId,
                                                newTodo.companyId,
                                                newTodo.vacancyId,
                                                newTodo.function,
                                                newTodo.presentationId
                                              )}
                                              state={{ origin: "dashboard" }}
                                            >
                                              <span
                                                key={`proceed-${index}`}
                                                data-bs-toggle="tooltip"
                                                title={t("Proceed")}
                                              >
                                                <RightArrow width={"2vw"} height={"2vw"} />
                                              </span>
                                            </Link>
                                          )}
                                        </div>
                                      </td>
                                    </tr>
                                  )}
                                </>
                              }
                            </>
                          </Fragment>
                        ))
                      ) : (
                        <tr className="border rounded-3">
                          <td
                            colSpan={7}
                            className="border-0 text-center py-3 no-records"
                          >
                            <span className="text-danger w-100 d-block text-center p-3">
                              {(userAuth.role === CONST.AREA_MANAGER || userAuth.role === CONST.OFFICE_MANAGER) ?
                                <>
                                  {t("No tasks for you, to see the team tasks click on overview")}
                                </> :
                                <>
                                  {t("No tasks")}
                                </>
                              }
                            </span>
                          </td>
                        </tr>
                      )}
                    </>
                  </tbody>
                </table>
              </div>
              {userAuth.role !== CANDIDATE_ROLE &&
                <Link
                  to={"/todos/overview"}
                  className="link-btn text-decoration-none"
                >
                  {t("Overview")}
                  <FontAwesomeIcon icon={faAngleRight} className="ms-4" />
                </Link>
              }
            </Tab>
            {canFollowUp && canFollowUp?.read &&
              <Tab
                eventKey="followups"
                title={
                  <span className="position-relative recruitment-card-title">
                    {t("Follow-ups")}
                    <span className="countIcon" title={t("Today's follow-ups")}>
                      {followUpData.todayFollowUpCount}
                    </span>
                  </span>
                }
              >
                <FollowUpTable
                  followUps={followUpData?.followUps}
                  handleFollowUpClose={handleFollowUpClose}
                />
              </Tab>
            }
          </Tabs>
        </div>
      </div>
      <div>
        <AssignTodoModal
          show={isAssignTodoModalOpen}
          onHide={closeAssignTodoModal}
          title={type === "newtodos" ? t("Assign to") : t("Transfer to")}
          todoId={todoId}
          todoExtensionId={todoExtensionId}
          onAssignTodoSuccess={handleAssignTodoSuccess}
          modalData={modalData}
          type={type}
        />
        <ModalPopup
          show={showTermsModal}
          showCloseButton={false}
          // onHide={handleModalClose}
          title={modal.title}
          body={modal.body}
          onConfirmButtonClick={handleSaveChanges}
          className="modal-lg"
          confirmTitle={t("Accept")}
          isInnerHTML={true}
        />
        <ModalPopup
          show={showSignatureModal}
          onHide={handleSignatureModalClose}
          title={t("Signature")}
          body={t("Please add signature")}
          onConfirmButtonClick={handleSignatureModal}
          className="modal-md"
          confirmTitle={t("Add")}
        />
      </div>
    </div>
  );
};

export default translate(TodoDashboard);
