import { t } from "components/CentralDataMangement/translation/Translation";
import { GENERAL } from "./VacancyConstants";

export const initialState = {
    tabDetails: [
        {
            id: "general",
            title: t("General"),
            showStatus: false,
            error: false,
            draft: false
        },
        {
            id: "employment",
            title: t("Employment"),
            showStatus: false,
            error: false,
            draft: false
        },
        {
            id: "competences",
            title: t("Competences"),
            showStatus: false,
            error: false,
            draft: false
        },
        {
            id: "offer",
            title: t("Offer"),
            showStatus: false,
            error: false,
            draft: false
        },
        {
            id: "requirement",
            title: t("Requirement"),
            showStatus: false,
            error: false,
            draft: false
        },
        {
            id: "jobDescription",
            title: t("Internal info"),
            showStatus: false,
            error: false,
            draft: false
        }
    ],

    general: {
        company: null,
        location: null,
        pc: null,
        sector: null,
        domain: null,
        function: null,
        empType: null,
        positions: "",
        formStatus: null,
        office: null,
        consultant: null,
        language: null,
        aliasName: "",
        brightId: null,
        pushToWebsite: 1,
        pushToVDAB: 1,
        isActive: 0,
        contactPerson: null
    },

    employment: {
        contract: null,
        employment: null,
        hrs: "",
        regime: null,
        shift: null,
        vdabTemplate: null,
        vdabCompetences: []
    },

    offer: {
        payType: null,
        starter: 0,
        experienced: 0,
        starterWage: "",
        experiencedWage: "",
        jobOffer: ""
    },

    requirement: {
        experience: null,
        driverLicence: [],
        education: [],
        additional: [],
        other: "",
        equiWorkExperience: 1,
        profileDescription: ""
    },

    currentTab: GENERAL,

    errors: {
        company: "",
        location: "",
        function: "",
        wage: "",
        domain: "",
        office: "",
        consultant: "",
        language: "",
        pc: "",
        aliasName: "",
        fileId: "",
        vdabTemplate: "",
        vdabCompetences: "",
        driverLicence:"",
        education:"",
    },

    jobDescription: {
        description: "",
        reasonForClosure: null,
        info: "",
        extraInfo: "",
        fileId: null,
        filePath: "",
        fileName: ""
    },

    options: {
        companyOptions: [],
        locationOptions: [],
        officeOptions: [],
        languageOptions: [],
        consultantOptions: [],
        vdabTemplateOptions: [],
        vdabTemplateList: [],
        vdabCompetencesOptions: [],
        contactPersonOptions: [],

        experienceOptions: [],
        driverLicOptions: [],
        educationOptions: [],
        additionalOptions: [],

        sectorOptions: [],
        pcOptions: [],
        domainOptions: [],
        functionOptions: [],
        employeeTypeOptions: [],

        contractOptions: [],
        employmentOptions: [],
        regimeOptions: [],
        shiftOptions: [],

        payTypeOptions: [],
        functionDomainLinking: [],
        pc: [],

        reasonForClosureOptions: [],
        cityOptions: [],
        countryOptions: []
    },

    competences: {
        competence: [],
        candidateLevels: [],
        childCompetences: {}
    }
};
