import { t } from "components/CentralDataMangement/translation/Translation";
import MultiSelectAtom from "components/common/atoms/MultiSelectAtom";
import SelectWithSearch from "components/common/atoms/SelectWithSearch";
import AutoCompleteDropDownForMap from "components/common/molecules/AutoCompleteDropDownForMap";
import { OptionProps } from "components/common/utlis/TypeAnnotations";
import React, { useState, useEffect, useRef } from "react";
import { Loader } from "@googlemaps/js-api-loader";
import { Accordion } from "react-bootstrap";
import Button from "components/common/atoms/Button";
import CheckBoxField from "components/common/atoms/CheckBoxField";

interface DropdownProps {
  fields: { [key: string]: any }; // Fields object (e.g., { office: "", officeRadius: 0 })
  onUpdate: (updatedFields: { [key: string]: any }) => void; // Callback to send all fields back
  className?: string
  dropDownlists?: { [key: string]: any };
  type?: string;
}

export interface FormData {
  offices: number[],
  officeRadius: number,
  location: object[],
  locationRadius: number,
  sectors: number[],
  domains: number[],
  functions: number[],
  selectionGroups: number[],
  regimes: number[],
  payTypes: number[],
  drivingLicences: number[],
  transports: number[],
  educationLevels: number[],
  employmentTypes: number[]
}

const SearchFields: React.FC<DropdownProps> = ({ fields, onUpdate, className, dropDownlists, type }) => {
  const [formData, setFormData] = useState<FormData>({
    offices: [],
    officeRadius: 0,
    location: [],
    locationRadius: 0,
    sectors: [],
    domains: [],
    functions: [],
    selectionGroups: [],
    regimes: [],
    payTypes: [],
    drivingLicences: [],
    transports: [],
    educationLevels: [],
    employmentTypes: []
  })

  const [dropdownList, setDropdownList] = useState({
    offices: [] as OptionProps[],
    locations: [] as OptionProps[],
    sectors: [] as OptionProps[],
    domains: [] as OptionProps[],
    functions: [] as OptionProps[],
    selectionGroups: [] as OptionProps[],
    regimes: [] as OptionProps[],
    payTypes: [] as OptionProps[],
    drivingLicences: [] as OptionProps[],
    transports: [] as OptionProps[],
    educationLevels: [] as OptionProps[],
    employmentTypes: [] as OptionProps[],
  })

  useEffect(() => {
    setFormData((prev) => ({
      ...prev,
      ...fields, // Update formData with new fields values
    }));
    setDropdownList((prev) => ({
      ...prev,
      ...dropDownlists, // Update formData with new fields values
    }));

  }, [fields, dropDownlists]);

  const handleSelectChange = (selectedOption: any, fieldName: string) => {
    setFormData((prev) => ({
      ...prev,
      [fieldName]: selectedOption,
    }));
  };

  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { name, checked, value } = event.target;
    setFormData((prev) => ({
      ...prev,
      [name]: Array.isArray(prev[name as keyof FormData])
        ? checked
          ? [...(prev[name as keyof FormData] as number[]), Number(value)]
          : (prev[name as keyof FormData] as number[]).filter(
            (item) => item !== Number(value)
          )
        : checked
          ? [Number(value)]
          : [],
    }));

  };

  const addressInputRef = useRef<HTMLInputElement | null>(null);
  useEffect(() => {
    let loader = new Loader({
      apiKey: `${process.env.REACT_APP_GOOGLE_MAP_API_KEY}`, // Replace with your API key
      version: "weekly",
      libraries: ["places"],
    });
    loader.load().then(() => {
      if (addressInputRef.current) {
        const autocomplete = new google.maps.places.Autocomplete(addressInputRef.current);
        autocomplete.addListener("place_changed", () => {
          const place = autocomplete.getPlace();
          if (place && place.formatted_address && place.geometry?.location) {
            const locationDetails = {
              address: place.formatted_address,
              lat: place.geometry.location.lat(),
              lng: place.geometry.location.lng(),
            };
            handleSelectChange(locationDetails, 'location');
          }
        });
      }
    });
  }, [])

  return (
    <div>
      <div className="scrollBarDesign matchingScrollWrapper mt-5 ">
        <Accordion className={`${className} marginBotttom1`}>
          <Accordion.Item eventKey="0">
            <Accordion.Header className="rounded-3 ">
              <div className="row w-100">
                {t("Office")}
              </div>
            </Accordion.Header>
            <Accordion.Body className="bg-white rounded-3">
              <div className="marginBottomPoint5" >
                <MultiSelectAtom
                  onChange={(selectedOption) =>
                    handleSelectChange &&
                    handleSelectChange(selectedOption, "offices")
                  }
                  name={"offices"}
                  options={dropdownList.offices}
                  value={formData.offices}
                  className="hideLabel"
                />
              </div>
              <SelectWithSearch
                title={t("Within a radius of")}
                isMandatory={false}
                search={true}
                options={dropdownList.locations}
                onChange={(e) => handleSelectChange(e, "officeRadius")}
                isMulti={false}
                name="officeRadius"
                value={formData.officeRadius}
              />
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
        <Accordion className={`${className} marginBotttom1`}>
          <Accordion.Item eventKey="1">
            <Accordion.Header className="rounded-3">
              <div className="row w-100">
                {t("Location")}
              </div>
            </Accordion.Header>
            <Accordion.Body className="bg-white rounded-3">
              <div className="marginBottomPoint5" >
                <AutoCompleteDropDownForMap
                  ref={addressInputRef}
                  placeholder="Search address with Google Maps"
                />
              </div>
              <div>
                <SelectWithSearch
                  title={t("Within a radius of")}
                  isMandatory={false}
                  search={true}
                  options={dropdownList.locations}
                  onChange={(e) => handleSelectChange(e, "locationRadius")}
                  isMulti={false}
                  name="locationRadius"
                  value={formData.locationRadius}
                />
              </div>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
        {type !== "vacancy" &&
          <>
            <Accordion className={`${className} marginBotttom1`}>
              <Accordion.Item eventKey="1">
                <Accordion.Header className="rounded-3">
                  <div className="row w-100">
                    {t("Sector")}
                  </div>
                </Accordion.Header>
                <Accordion.Body className="bg-white rounded-3">
                  <MultiSelectAtom
                    onChange={(selectedOption) =>
                      handleSelectChange &&
                      handleSelectChange(selectedOption, "sectors")
                    }
                    name={"sectors"}
                    options={dropdownList.sectors}
                    value={formData.sectors}
                    className="hideLabel"
                  />
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
            <Accordion className={`${className} marginBotttom1`}>
              <Accordion.Item eventKey="1">
                <Accordion.Header className="rounded-3">
                  <div className="row w-100">
                    {t("Domain")}
                  </div>
                </Accordion.Header>
                <Accordion.Body className="bg-white rounded-3">
                  <MultiSelectAtom
                    onChange={(selectedOption) =>
                      handleSelectChange &&
                      handleSelectChange(selectedOption, "domains")
                    }
                    name={"domains"}
                    options={dropdownList.domains}
                    value={formData.domains}
                    className="hideLabel"
                  />
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
            <Accordion className={`${className} marginBotttom1`}>
              <Accordion.Item eventKey="1">
                <Accordion.Header className="rounded-3">
                  <div className="row w-100">
                    {t("Function")}
                  </div>
                </Accordion.Header>
                <Accordion.Body className="bg-white rounded-3">
                  <MultiSelectAtom
                    onChange={(selectedOption) =>
                      handleSelectChange &&
                      handleSelectChange(selectedOption, "functions")
                    }
                    name={"functions"}
                    options={dropdownList.functions}
                    value={formData.functions}
                    className="hideLabel"
                  />
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
            <Accordion className={`${className} marginBotttom1`}>
              <Accordion.Item eventKey="1">
                <Accordion.Header className="rounded-3">
                  <div className="row w-100">
                    {t("Selection group")}
                  </div>
                </Accordion.Header>
                <Accordion.Body className="bg-white rounded-3">
                  {dropdownList.selectionGroups && dropdownList.selectionGroups.map((item, index) => (
                    <div className="col-sm-12 col-md-12" key={index}>
                      <CheckBoxField
                        label={item.label}
                        name="selectionGroups"
                        onChangeHandler={(e) => handleChange(e)}
                        isChecked={formData.selectionGroups.includes(item.value as number) ? true : false}
                        className="text-dark"
                        lineHeight="1.1vw"
                        id={"selectionGroups"}
                        value={item.value}
                      />
                    </div>
                  ))}
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
            <Accordion className={`${className} marginBotttom1`}>
              <Accordion.Item eventKey="1">
                <Accordion.Header className="rounded-3">
                  <div className="row w-100">
                    {t("Regimes")}
                  </div>
                </Accordion.Header>
                <Accordion.Body className="bg-white rounded-3">
                  {dropdownList.regimes && dropdownList.regimes.map((item, index) => (
                    <div className="col-sm-12 col-md-12" key={index}>
                      <CheckBoxField
                        label={item.label}
                        name="regimes"
                        onChangeHandler={(e) => handleChange(e)}
                        isChecked={formData.regimes.includes(item.value as number) ? true : false}
                        className="text-dark"
                        lineHeight="1.1vw"
                        id={"regimes"}
                        value={item.value}
                      />
                    </div>
                  ))}
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
            <Accordion className={`${className} marginBotttom1`}>
              <Accordion.Item eventKey="1">
                <Accordion.Header className="rounded-3">
                  <div className="row w-100">
                    {t("Employment types")}
                  </div>
                </Accordion.Header>
                <Accordion.Body className="bg-white rounded-3">
                  {dropdownList.employmentTypes && dropdownList.employmentTypes.map((item, index) => (
                    <div className="col-sm-12 col-md-12" key={index}>
                      <CheckBoxField
                        label={item.label}
                        name="employmentTypes"
                        onChangeHandler={(e) => handleChange(e)}
                        isChecked={formData.employmentTypes.includes(item.value as number) ? true : false}
                        className="text-dark"
                        lineHeight="1.1vw"
                        id={"employmentTypes"}
                        value={item.value}
                      />
                    </div>
                  ))}
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
            <Accordion className={`${className} marginBotttom1`}>
              <Accordion.Item eventKey="1">
                <Accordion.Header className="rounded-3">
                  <div className="row w-100">
                    {t("Pay type")}
                  </div>
                </Accordion.Header>
                <Accordion.Body className="bg-white rounded-3">
                  {dropdownList.payTypes && dropdownList.payTypes.map((item, index) => (
                    <div className="col-sm-12 col-md-12" key={index}>
                      <CheckBoxField
                        label={item.label}
                        name="payTypes"
                        onChangeHandler={(e) => handleChange(e)}
                        isChecked={formData.payTypes.includes(item.value as number) ? true : false}
                        className="text-dark"
                        lineHeight="1.1vw"
                        id={"payTypes"}
                        value={item.value}
                      />
                    </div>
                  ))}
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </>
        }
        {/* <Accordion className={className}>
                    <Accordion.Item eventKey="1">
                        <Accordion.Header className="rounded-3">
                            <div className="row w-100">
                                {t("Driving licenses")}
                            </div>
                        </Accordion.Header>
                        <Accordion.Body className="bg-white rounded-3">
                            <MultiSelectAtom
                                onChange={(selectedOption) =>
                                    handleSelectChange &&
                                    handleSelectChange(selectedOption, "drivingLicences")
                                }
                                name={"drivingLicences"}
                                options={dropdownList.drivingLicences}
                                value={formData.drivingLicences}
                            />
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion> */}
        {/* <Accordion className={className}>
                    <Accordion.Item eventKey="1">
                        <Accordion.Header className="rounded-3">
                            <div className="row w-100">
                                {t("Transports")}
                            </div>
                        </Accordion.Header>
                        <Accordion.Body className="bg-white rounded-3">
                            {dropdownList.transports && dropdownList.transports.map((item, index) => (
                                <div className="col-sm-12 col-md-12" key={index}>
                                    <CheckBoxField
                                        label={item.label}
                                        name="transports"
                                        onChangeHandler={(e) => handleChange(e)}
                                        isChecked={formData.transports.includes(item.value as number) ? true : false}
                                        className="text-dark"
                                        lineHeight="1.1vw"
                                        id={"transports"}
                                        value={item.value}
                                    />
                                </div>
                            ))}
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion> */}
        {/* <Accordion className={className}>
                    <Accordion.Item eventKey="1">
                        <Accordion.Header className="rounded-3">
                            <div className="row w-100">
                                {t("Studies")}
                            </div>
                        </Accordion.Header>
                        <Accordion.Body className="bg-white rounded-3">
                            <MultiSelectAtom
                                onChange={(selectedOption) =>
                                    handleSelectChange &&
                                    handleSelectChange(selectedOption, "educationLevels")
                                }
                                name={"educationLevels"}
                                options={dropdownList.educationLevels}
                                value={formData.educationLevels}
                            />
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion> */}
      </div >
      <div className="row">
        <div className="col-12">
          <div className="d-flex justify-content-end align-items-center">
            <Button
              title={"Search"}
              type="button"
              handleClick={() => onUpdate(formData)}
              className="form-button shadow-none  text-center"
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default SearchFields