import React, { useState } from "react";
import CardCircle from "components/common/atoms/CardCircle";
import { faArrowUpFromBracket } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  t,
  translate,
} from "components/CentralDataMangement/translation/Translation";
import { useFormContext } from "components/CandidateCreation/context/Context";
import UploadPhotoModal from "./tabContentComponents/UploadPhotoModal";
import { FILE_URL } from "../../../../routes/ApiEndpoints";
import "../css/Candidate.css";
import FileUploadForCandidate from "components/common/molecules/FileUploadForCandidate";
import { Link, useLocation, useParams } from "react-router-dom";
import { DocumnetChecklistProps } from "components/CandidateCreation/annotations/CandidateAnnotations";
import { formatDate } from "components/common/utlis/HelperFunctions";
import CalendarIcon from "static/images/CalendarIcon";
import { SetCvParsingData } from "../formData/SetCvParsingData";
import RightArrow from "static/images/RightArrow";
import { useSelector } from "react-redux";
import { selectAuth } from "features/auth/AuthSlice";

const CandidateProfileCard: React.FC = () => {
  const location = useLocation();
  const userAuth = useSelector(selectAuth);

  const { state, dispatch, isViewMode } = useFormContext();
  const { candidateId } = useParams<{ candidateId: string }>();

  const [isUploadPhotoModalOpen, setUploadPhotoModalOpen] = useState(false);

  const openUploadPhotoModal = () => {
    setUploadPhotoModalOpen(true);
  };

  const closeUploadPhotoModal: any = () => {
    setUploadPhotoModalOpen(false);
    dispatch({
      type: "UPDATE_FIELD_ERROR",
      fieldName: "picFileId",
      error: "",
    });
  };

  const fileUploadStyleObject: React.CSSProperties = {
    width: "35px",
    height: "35px",
    borderRadius: "10px",
  };

  //** resume automatic upload to docs  INTIAL STATE*/
  const [documentData, setDocumentData] = useState<DocumnetChecklistProps>({
    id: null,
    documentName: "",
    documentCategory: { label: "cv", value: 12 },
    documentId: null,
    fileName: "",
    filePath: "",
    validFrom: null,
    validTo: null,
    extension: "",
    editId: null,
    uploadedAt: new Date(),
    uploadedBy: userAuth.name,
  });

  const fileUploadResponse = async (responseObj: any) => {
    const step = responseObj["step"];
    const response = responseObj["response"];

    await SetCvParsingData(responseObj["response"], dispatch, state);

    const fieldMappings: { [key: string]: string[] } = {
      cvFileId: ["cvFileId", "cvFileName", "cvPath"],
      picFileId: ["picFileId", "picFileName", "picFilePath"],
    };

    const fieldNames = fieldMappings[step];

    if (fieldNames) {
      fieldNames.forEach((fieldName: string, index: number) => {
        dispatch({
          type: "UPDATE_PROFILE_FIELD",
          field: fieldName,
          value: response[index],
        });
      });
    }

    //** resume automatic upload to docs  UPLOADING....*/

    if (step === "cvFileId") {
      const docFieldNames = ["documentId", "fileName", "filePath"];

      const updatedDocumentData = { ...documentData };
      docFieldNames.forEach((fieldName: string, key: number) => {
        updatedDocumentData[fieldName] = response[key];
      });

      // Extract file name and extension
      const fileNameWithExtension = response[1];
      const lastDotIndex = fileNameWithExtension.lastIndexOf(".");
      const fileName =
        lastDotIndex !== -1
          ? fileNameWithExtension.substring(0, lastDotIndex)
          : fileNameWithExtension;
      const extension =
        lastDotIndex !== -1
          ? fileNameWithExtension.substring(lastDotIndex + 1)
          : "";

      updatedDocumentData.extension = extension;
      updatedDocumentData.documentName = fileName;
      updatedDocumentData.editId = state.documentChecklist.length;

      setDocumentData(updatedDocumentData);

      dispatch({
        type: "ADD_DOCUMENT_FIELD",
        data: updatedDocumentData,
        index: state.documentChecklist.length,
      });
    }
  };

  const returnFileUploadErrors = (errorResposne: any) => {
    const step = errorResposne["step"];
    switch (step) {
      case "cvFileId":
        dispatch({
          type: "UPDATE_FIELD_ERROR",
          fieldName: step,
          error: errorResposne["error"],
        });
        break;
      case "picFileId":
        dispatch({
          type: "UPDATE_FIELD_ERROR",
          fieldName: step,
          error: errorResposne["error"],
        });
        break;
      default:
        return 0;
    }
  };

  const deleteResponse = (field: string, index: number) => {
    const fieldMappings: { [key: string]: string[] } = {
      cvFileId: ["cvFileId", "cvFileName", "cvPath"],
      picFileId: ["picFileId", "picFileName", "picFilePath"],
    };

    //** Delete from doc checklist if delete resume here*/
    const fieldNames = fieldMappings[field];
    const fileIdToDeleteInDoc = state.profileCard.cvFileId;
    const indexToRemove = state.documentChecklist.findIndex(
      (document) => document.documentId === fileIdToDeleteInDoc
    );

    if (indexToRemove !== -1) {
      dispatch({ type: "REMOVE_DOCUMENT_FIELD", indexToRemove });
    }

    /** */

    if (fieldNames) {
      fieldNames.forEach((fieldName: string, index: number) => {
        dispatch({
          type: "UPDATE_PROFILE_FIELD",
          field: fieldName,
          value: fieldName.endsWith("FileId") ? null : "",
        });
      });
    }

    if (field === "cvFileId") {
      dispatch({ type: "RESET_ALL_FIELDS" });
    }
  };

  const number =
    state.general.street.trim() !== ""
      ? `, ${state.general.number}`
      : state.general.number;
  const country =
    state.general.country?.label === undefined
      ? ""
      : state.general.country?.label;
  const city =
    state.general?.country?.value === 29
      ? state.general?.city
        ? state.general?.city?.label
        : ""
      : state.general?.cityOther;

  const functionsList = state.jobProfile.desiredFunctions
    .map((item) => item.label)
    .join(", ");

  const destination =
    new URLSearchParams(location.search).get("destination") ||
    "/manage-candidates";

  const getAge = () => {
    const birthDate = state.general.dateOfBirth;

    if (!birthDate) {
      return null; // Return null if date of birth is not available
    }

    const birthYear = birthDate.getFullYear(); // Get birth year

    const currentDate = new Date(); // Get current date
    const currentYear = currentDate.getFullYear(); // Get current year

    return currentYear - birthYear; // Calculate and return the age
  };

  return (
    <div className="row">
      <div className="col-md-7">
        <div
          style={{
            marginLeft: "3.5vw",
          }}
        >
          <div
            className="card position-relative border-0"
            style={{
              backgroundColor: "var(--profileBgColor)",
              padding: "2.5vw",
              borderRadius: "1vw",
            }}
          >
            <div className="cv-upload">
              <CardCircle
                position="absolute"
                width="7.813vw"
                height="7.813vw"
                backgroundColor="var(--primaryBrandColor)"
                left="0%"
                top={"50%"}
                transform={`translate(-3.5vw, -3.9vw)`} // Centering horizontally and vertically
                className={`me-5 flex-column cursor-pointer justify-content-center ${
                  isViewMode ? "form disabled" : "form"
                }`}
                color="var(--color-white)"
                inputClass={"mb-2"}
                onClick={openUploadPhotoModal}
              >
                {state.profileCard.picFileName !== "" ? (
                  <img
                    src={
                      state.profileCard.picFilePath?.startsWith("https")
                        ? `${state.profileCard.picFilePath}`
                        : `${FILE_URL}/${state.profileCard.picFilePath}`
                    }
                    alt={t("Uploaded")}
                    style={{
                      backgroundColor: "var(--color-white)",
                      borderRadius: "50%",
                      boxShadow: "rgb(248 216 235) 0.052vw 0vw 0.521vw 0.156vw",
                    }}
                    width="100%"
                    height="100%"
                  />
                ) : (
                  <>
                    <FontAwesomeIcon
                      icon={faArrowUpFromBracket}
                      className="fa-lg mb-2"
                    />
                    <span className="">{t("Upload photo")}</span>
                  </>
                )}
              </CardCircle>
            </div>
            <div className="row">
              <UploadPhotoModal
                show={isUploadPhotoModalOpen}
                onHide={closeUploadPhotoModal}
                title={t("Profile photo")}
                className="candidateProfilePicUpload"
              />
              <div className="col-md-10 ms-auto">
                <div className={`color-dark-pink name-text text-start mb-3`}>
                  {state.general.firstName} {state.general.lastName}
                </div>
                <div
                  className="job-title text-start"
                  style={{ marginBottom: "1vw" }}
                >
                  {state.jobProfile.desiredFunctions.length > 0 && (
                    <>
                      <span>{t("looking for a job as")}</span>
                      <span>
                        <strong> {functionsList}</strong>
                      </span>
                    </>
                  )}
                </div>
                <div className="candidate-person-details">
                  <div
                    className="d-flex align-items-center"
                    style={{ marginBottom: "0.8vw" }}
                  >
                    <img
                      src="/static/images/mobile.png"
                      alt={t("Mobile")}
                      className="profileCardImage"
                    />
                    <span className={`ms-3`}>{state.general.mobNumber}</span>
                  </div>
                  {state.general?.dateOfBirth &&
                    state.general?.dateOfBirth !== null && (
                      <div
                        className="d-flex align-items-center "
                        style={{ marginBottom: "0.8vw" }}
                      >
                        <span className="color-dark-pink profileCalendar">
                          <CalendarIcon />
                        </span>
                        <span className={`ms-3`}>
                          {`${formatDate(state.general.dateOfBirth)} (${
                            state.general.dateOfBirth !== null
                              ? `${getAge()}`
                              : `${state.general.age}`
                          } ${t("Years")})`}
                        </span>
                      </div>
                    )}
                  <div
                    className="d-flex align-items-center"
                    style={{ marginBottom: "0.8vw" }}
                  >
                    <img
                      src="/static/images/mail.png"
                      alt={t("Mail")}
                      className="profileCardImage"
                    />
                    <span className={`ms-3`}>{state.general.email}</span>
                  </div>
                  <div
                    className="d-flex align-items-start"
                    style={{ marginBottom: "0.8vw" }}
                  >
                    <img
                      src="/static/images/home.png"
                      alt={t("Address")}
                      className="profileCardImage"
                    />
                    <span className={`ms-3`}>
                      {state.general.street && (
                        <div style={{ marginBottom: "0.8vw" }}>
                          {`${state.general.street}${number}`}
                        </div>
                      )}
                      <span>
                        {`${city}`}
                        {state.general.zipCode && ` ${state.general.zipCode}`}
                      </span>
                      <div>{`${country}`}</div>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className="col-xxl-3 col-4 ps-4 align-self-center"
        style={{ paddingTop: "1vw" }}
      >
        <div className="shout-icon d-flex align-items-center">
          <div className="d-flex">
            <img
              src="/static/images/megaphone.svg"
              alt="shout"
              className="megaPhoneIcon"
            />
            <div className="available-text mb-2 fraunces-italic color-dark-pink">
              <div>{t("Available")}</div>
              <div>{t("for work!")}</div>
            </div>
          </div>
        </div>
        <div className="text-center">
          <div
            className={`${
              state.profileCard.cvFileName !== ""
                ? "candidate-cv-download"
                : "candidate-cv-upload"
            } `}
          >
            <div>
              <FileUploadForCandidate
                id="cvFileId"
                name="cvFileId"
                edit={false}
                fileId={
                  state.profileCard.cvFileId ? state.profileCard.cvFileId : null
                }
                filePath={
                  state.profileCard.cvPath ? state.profileCard.cvPath : ""
                }
                fileName={
                  state.profileCard.cvFileName
                    ? state.profileCard.cvFileName
                    : ""
                }
                style={fileUploadStyleObject}
                multiple={false}
                uploadPath="candidate-cv"
                formats=".pdf,.docx,.doc"
                returnFileUploadErrors={returnFileUploadErrors}
                fileUploadResponse={fileUploadResponse}
                deleteResponse={deleteResponse}
                maxFileSize={10 * 1024 * 1024}
                label=""
                placeholder=""
                page="candidateCv"
                modalPopup={true}
                modalDetails={{
                  title: "Confirmation: Delete CV",
                  description:
                    "Are you sure do you want to delete the CV? It will erase the data which is filled automatically by parsing the CV",
                }}
                isViewMode={isViewMode}
              />
            </div>
          </div>
          {isViewMode && (
            <>
              <div className="d-flex matchingCandidateBtn">
                <Link
                  className="form-button matchingBtn"
                  to={""}
                  onClick={(e) => {
                    e.preventDefault(); // Prevents the default Link navigation
                    window.location.href = `/candidate/edit/${candidateId}?tabId=general&destination=${destination}`; // Navigates and reloads
                  }}
                >
                  <div className="d-flex align-items-center justify-content-between">
                    {t("Edit candidate")} &nbsp;
                    <span style={{ marginLeft: "0.25vw" }}>
                      <RightArrow />
                    </span>
                  </div>
                </Link>
              </div>

              <div className="d-flex matchingCandidateBtn">
                <Link
                  className="form-button matchingBtn"
                  to={`/manage-candidates`}
                >
                  <div className="d-flex align-items-center justify-content-between">
                    {t("Manage candidates")} &nbsp;
                    <span style={{ marginLeft: "0.25vw" }}>
                      <RightArrow />
                    </span>
                  </div>
                </Link>
              </div>
            </>
          )}
        </div>
        {state.fieldError.cvFileId && (
          <div className="height-20 mt-4 pt-2">
            <span className="text-danger">{state.fieldError.cvFileId}</span>
          </div>
        )}
      </div>
    </div>
  );
};

export default translate(CandidateProfileCard);
