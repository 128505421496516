import React, { useEffect, useState } from "react";

interface ToggleButtonProps {
  value?: boolean; // Optional boolean value to set initial state
  onToggle?: (value: boolean) => void; // Optional function to handle toggle
  disabled?: boolean; // Optional boolean to disable the button
  toggleClassName?: string;
  activeText?: string; // Custom text for the active state
  inactiveText?: string; // Custom text for the inactive state
}

const ToggleButton: React.FC<ToggleButtonProps> = ({
  value = false,
  onToggle = () => {},
  disabled = false, // Default to false
  toggleClassName,
  activeText = "Active", // Default text for active state
  inactiveText = "Inactive", // Default text for inactive state
}) => {
  const ToggleButton = require("react-toggle-button");
  const [isActive, setIsActive] = useState(value);

  useEffect(() => {
    setIsActive(value); // Sync internal state with the prop value
  }, [value]);

  const handleToggle = () => {
    if (disabled) return; // Do nothing if disabled
    const newValue = !isActive;
    setIsActive(newValue);
    onToggle(newValue); // Notify parent about the change
  };

  return (
    <div className={`custom-toggle-container ${isActive ? "active" : ""} ${toggleClassName}`}>
      <ToggleButton
        value={isActive}
        onToggle={handleToggle}
        inactiveLabel={inactiveText} // Custom text for inactive state
        activeLabel={activeText}    // Custom text for active state
        colors={{
          active: {
            base: "#e55496",
          },
          inactive: {
            base: "#898989",
          },
          activeThumb: {
            base: "#fff",
          },
          inactiveThumb: {
            base: "#fff",
          },
        }}
        inactiveThumbStyle={{
          boxShadow: "none",
        }}
      />
    </div>
  );
};

export default ToggleButton;
