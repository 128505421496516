import Title from "components/common/atoms/Title";
import { t, translate } from "../translation/Translation";
import {
  Link,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import SelectWithSearch from "components/common/atoms/SelectWithSearch";
import { useEffect, useState } from "react";
import { OptionProps } from "components/common/utlis/TypeAnnotations";
import Button from "components/common/atoms/Button";
import Reset from "static/images/Reset";
import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import { Spinner } from "react-bootstrap";
import Pagination from "components/common/atoms/Pagination";
import { useSelector } from "react-redux";
import { addUserDetails, selectAuth } from "features/auth/AuthSlice";
import { ApiCall } from "components/common/services/ApiServices";
import {
  AREA_MANAGER,
  CANDIDATE_MICROSERVICE,
  CANDIDATE_ROLE,
  CENTRAL_DATA_MANAGEMENT_MICROSERVICE,
  EMPLOYER,
  OFFICE_MANAGER,
  RECRUITER,
} from "Constants";
import CustomNotify from "components/common/atoms/CustomNotify";
import AssignTodoModal, { ModalData } from "./AssignTodoModal";
import { mapToSelect } from "components/common/utlis/MapToSelect";
import "./css/todos.css";
import PickUp from "static/images/PickUp";
import AssignTo from "static/images/AssignTo";
import InfoIcon from "static/images/InfoIcon";
import RightArrow from "static/images/RightArrow";
import GetQueryParams from "components/common/services/GetQueryParams";
import {
  ACCEPT_TERMS_AND_CONDITIONS,
  ASSIGN_TODO,
  GET_TODOS,
} from "routes/ApiEndpoints";
import EditIcon from "static/images/EditIcon";
import CloseFile from "static/images/CloseFile";
import ViewIcon from "static/images/ViewIcon";
import ModalPopup from "components/common/atoms/ModalPopup";
import AutoCompleteDropdown from "components/common/atoms/AutoCompleteDropdown";
import { useDispatch } from "react-redux";
import ResetBtn from "components/common/atoms/ResetBtn";
import SpinnerWrapper from "components/common/atoms/SpinnerWrapper";

export interface formData {
  candidate: OptionProps[];
  functn: OptionProps[];
  stage: OptionProps[];
  office: OptionProps[];
}

export interface todosData {
  id: number;
  candidateName: string;
  companyName: string;
  function: string;
  todoId: number;
  stage: string;
  candidateId: number;
  stageId: number;
  assignedBy: string;
  assignedTo: string;
  assignedToId: number;
  companyId: number;
  vacancyId: number;
  todoExtensionPrevId: number;
  presentationId: number | null;
  status: number | null;
}

export interface newTodosData {
  candidateName: string;
  registeredOn: string;
  todoId: number;
  candidateId: number;
  stage: string;
  functionNames: string;
}

const TodosOverview: React.FC = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [dropDownData, setDropDownData] = useState<formData>({
    candidate: [],
    functn: [],
    stage: [],
    office: [],
  });
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [isAssignTodoModalOpen, setAssignTodoModalOpen] = useState(false);
  const [todoId, setTodoId] = useState<any>();
  const [todoExtensionId, setTodoExtensionId] = useState<any>();
  const [todos, setTodos] = useState([] as todosData[]);
  const [newTodos, setNewTodos] = useState([] as newTodosData[]);
  const [modalData, setModalData] = useState<ModalData>({
    officeDropdown: [],
    stageId: null,
    candidateId: null,
  });

  const openAssignTodoModal = (
    todoId: number,
    todoExtensionId: number | null,
    stageId: number,
    candidateId: number | null
  ) => {
    setTodoId(todoId);
    setTodoExtensionId(todoExtensionId);
    setModalData((prevData) => ({
      ...prevData,
      stageId: stageId,
      candidateId: candidateId,
    }));
    setAssignTodoModalOpen(true);
  };

  const closeAssignTodoModal: any = () => {
    setAssignTodoModalOpen(false);
  };

  //initiliaze the formData state for search fields
  const [formData, setFormData] = useState<formData>({
    candidate: [],
    functn: [],
    stage: [],
    office: [],
  });
  const userAuth = useSelector(selectAuth);

  const queryParams = GetQueryParams();
  const date = queryParams.get("date") ?? "";
  const stage = queryParams.get("stage");
  const template = queryParams.get("template");
  const candidateId = queryParams.get("candidateId");

  const fetchTodoDetails = async (formData = {}, page = 1) => {
    setLoading(true);
    const type = searchParams.get("type");

    const data = {
      formData: formData,
      page: page,
      userId: userAuth.userId,
      userRole: userAuth.role,
      type: type ?? "mytodos",
      candidateId: candidateId,
      date: date,
      template: template,
    };

    const response = await ApiCall.service(
      `${GET_TODOS}/${stage}`,
      "POST",
      data,
      false,
      CENTRAL_DATA_MANAGEMENT_MICROSERVICE
    );
    if (response.status === 200) {
      if (type === "mytodos" || type === null || type === "completedtodos") {
        const assignedTodos = response.data[0];
        setTodos(assignedTodos);
      } else {
        const newTodos = response.data[0];
        setNewTodos(newTodos);
      }

    // if (
    //   userAuth.role === CANDIDATE_ROLE &&
    //   response?.data?.["termsAndConditions"]
    // ) {
    //   setShowModal(true);
    //   setModal(response?.data["termsAndConditions"]);
    // } else {
    //   if (userAuth.isSignature === false) {
    //     setShowSignatureModal(true);
    //   }
    // }

      setTotalPages(response.totalPages);

      const functionsList = response.data?.["functionsList"]
        ? mapToSelect(response.data["functionsList"], "function_name")
        : [];
      const officesList = response.data?.["officesList"]
        ? mapToSelect(response.data["officesList"])
        : [];
      const stages = response.data?.["stagesList"]
        ? mapToSelect(response.data["stagesList"])
        : [];
      setDropDownData((prevData) => ({
        ...prevData,
        functn: functionsList,
        stage: stages,
        office: officesList,
      }));
      setModalData((prevData) => ({
        ...prevData,
        officeDropdown: officesList,
      }));
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchTodoDetails();
  }, [searchParams]);

  const handlePageChange = (newPage: number) => {
    setCurrentPage(newPage);
    fetchTodoDetails(formData, newPage);
  };
  const handleSearch = (e: React.FormEvent) => {
    e.preventDefault();
    if (
      formData.candidate.length === 0 &&
      formData.functn.length === 0 &&
      formData.stage.length === 0 &&
      formData.office.length === 0
    ) {
      CustomNotify({
        type: "warning",
        message: t("Search input not provided"),
      });
      fetchTodoDetails();
    } else {
      fetchTodoDetails(formData, 1);
    }
  };

  const handleReset = () => {
    setFormData({
      candidate: [],
      functn: [],
      stage: [],
      office: [],
    });
    fetchTodoDetails();
  };

  //changehandler for search fields
  const handleSelectChange = (selectedOption: any, fieldName: string) => {
    setFormData((prevData) => ({
      ...prevData,
      [fieldName]: selectedOption,
    }));
  };

  const handleTypeFilter = (
    type: string,
    e: React.MouseEvent<HTMLButtonElement>
  ) => {
    e.preventDefault();
    if (type === "") {
      const currentUrl = window.location.href;
      if (currentUrl.includes("?")) {
        const newUrl = currentUrl.split("?")[0];
        window.location.replace(newUrl);
      }
    } else {
      searchParams.set("type", type);
      setSearchParams(searchParams);
    }

    setCurrentPage(1);
  };

  const handleAssignTodoSuccess = () => {
    fetchTodoDetails();
  };

  const type = searchParams.get("type");

  const assignTodo = async (
    event: any,
    todoId: number | null,
    action: string,
    stageId: number | null,
    candidateId: number | null
  ) => {
    const data = {
      todoId: todoId,
      userId: userAuth.userId,
      actionType: action,
      stageId: stageId,
      candidateId: candidateId,
    };

    if (action === "pickUpTodo") {
      const response = await ApiCall.service(
        ASSIGN_TODO,
        "POST",
        data,
        false,
        CENTRAL_DATA_MANAGEMENT_MICROSERVICE
      );
      if (response.status === 200) {
        fetchTodoDetails(); //To remove assigned todos from newTodos Tab
        CustomNotify({ type: "success", message: t(response.msg) });
        searchParams.set("type", "mytodos");
        setSearchParams(searchParams);
      }
    }
  };

  const getLinkForStage = (
    todoExtensionId: number | null,
    stageId: number | null,
    todoId: number | null,
    candidateId: number | null,
    companyId: number | null,
    vacancyId: number | null,
    functionName: string | null,
    presentationId: number | null,
    role = userAuth.role
  ): string => {
    let link = "/todos/overview";

    switch (stageId) {
      case 1:
        link = `/candidate/edit/${candidateId}`;
        break;
      case 2:
        link = `/screening/${candidateId}`;
        break;
      case 3:
        link = `/matching-list/candidate/${candidateId}`;
        break;
      case 4:
        link = `/todos/company-pre-interview/${candidateId}/${companyId}/${todoExtensionId}/${todoId}/${functionName}/${presentationId}/${vacancyId}`;
        break;
      case 5:
        link = `/todos/candidate-pre-interview/${candidateId}/${companyId}/${todoExtensionId}/${todoId}/${functionName}/${presentationId}`;
        break;
      case 6:
        link = `/todos/pre-interview/${candidateId}/${companyId}/${todoExtensionId}/${todoId}/${functionName}/${vacancyId}/${presentationId}`;
        break;
      case 7:
        link = `/todos/interview-feedback/${candidateId}/${companyId}/${todoExtensionId}/${todoId}/${functionName}/${presentationId}`;
        break;
      case 8:
        link = `/todos/wage-discussion/${candidateId}/${companyId}/${todoExtensionId}/${todoId}/${vacancyId}/${functionName}/${presentationId}`;
        break;
      case 9:
        link = `/todos/contract-info-page/${candidateId}/${todoExtensionId}/${todoId}/${presentationId}/${functionName}/${companyId}/${stageId}`;
        break;
      case 10:
        link = `/todos/contract/${candidateId}/${todoExtensionId}/${todoId}/${presentationId}/${functionName}/${companyId}/${stageId}`;
        break;
      default:
        link = `/todos/overview`;
    }
    return link;
  };

  //for candidate accept terms modal pop up
  const [showModal, setShowModal] = useState(false);
  const [modal, setModal] = useState({
    body: "",
    title: "",
  });

  const handleSaveChanges = async () => {
    setShowModal(false);
    setShowSignatureModal(true);
    const data = {
      termsAccepted: true,
      candidateId: userAuth.candidateId,
    };
    const response = await ApiCall.service(
      ACCEPT_TERMS_AND_CONDITIONS,
      "POST",
      data,
      true,
      CANDIDATE_MICROSERVICE
    );
  };

  //Remainder for adding signature whenever a user login untill he adds his signature
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [showSignatureModal, setShowSignatureModal] = useState(false);
  const handleSignatureModalClose = () => {
    setShowSignatureModal(false);

    dispatch(addUserDetails({ isSignature: true }));
  };
  const handleSignatureModal = () => {
    setShowSignatureModal(false);
    navigate("/add/signature");
    dispatch(addUserDetails({ isSignature: true }));
  };

  return (
    <>
      <Title
        title={
          stage === null
            ? t("Todos")
            : t(`${stage.charAt(0).toUpperCase() + stage.slice(1)}`)
        }
      />
      <div className="form-height-dashboard">
        <div className="search-bar">
          {userAuth.role !== CANDIDATE_ROLE &&
            userAuth.role !== EMPLOYER &&
            stage === null && (
              <div className="row">
                <div className="col-md-12 vacancy-tabs">
                  <button
                    className={`link ${
                      type === "mytodos" || type === null ? "active" : ""
                    } text-decoration-none border-0 bg-transparent marginRight1 ps-0`}
                    onClick={(e) => handleTypeFilter("mytodos", e)}
                    style={{ cursor: "pointer" }}
                  >
                    {t("My todos")}
                  </button>
                  <button
                    className={`link ${
                      type === "newtodos" ? "active" : ""
                    } text-decoration-none border-0 bg-transparent ps-0`}
                    onClick={(e) => handleTypeFilter("newtodos", e)}
                    style={{ cursor: "pointer" }}
                  >
                    {t("New todos")}
                  </button>
                </div>
              </div>
            )}
          {userAuth.role !== CANDIDATE_ROLE &&
            userAuth.role !== EMPLOYER &&
            stage !== null && (
              <>
                {stage !== "new" &&
                  stage !== "presented" &&
                  stage !== "shortlist" && (
                    <div className="row pb-4 pt-2">
                      <div className="col-md-12 vacancy-tabs">
                        <button
                          className={`link ${
                            type === "mytodos" || type === null ? "active" : ""
                          } text-decoration-none border-0 bg-transparent marginRight1 ps-0`}
                          onClick={(e) => handleTypeFilter("mytodos", e)}
                          style={{ cursor: "pointer" }}
                        >
                          {t("In progress")}
                        </button>

                        <button
                          className={`link ${
                            type === "completedtodos" ? "active" : ""
                          } text-decoration-none border-0 bg-transparent ps-0`}
                          onClick={(e) => handleTypeFilter("completedtodos", e)}
                          style={{ cursor: "pointer" }}
                        >
                          {t("Completed")}
                        </button>
                      </div>
                    </div>
                  )}
              </>
            )}
          <div className="row" style={{ paddingTop: "1vw" }}>
            {userAuth.role !== CANDIDATE_ROLE && (
              <div className="col-md-3">
                <SelectWithSearch
                  search={true}
                  options={dropDownData.office}
                  title={t("Office")}
                  onChange={(e) => handleSelectChange(e, "office")}
                  isMulti={true}
                  name="office"
                  value={formData.office}
                  isTranslate={true}
                />
              </div>
            )}
            {userAuth.role !== CANDIDATE_ROLE &&
              template !== "candidateCorner" && (
                <div className="col-3 autoCompleteFunction">
                  <AutoCompleteDropdown
                    label={t("Candidate")}
                    placeholder={t("Type to search") + "..."}
                    microService={CANDIDATE_MICROSERVICE}
                    onChange={(e) => handleSelectChange(e, "candidate")}
                    value={formData.candidate}
                    columnName="first_name"
                    concat="last_name"
                    modelKey="candidate"
                    name="candidate"
                    valueColumn="candidate_id"
                    filter={true}
                    filterInfo={[
                      {
                        column: "candidates.candidate_status",
                        value: 0,
                        condition: "where",
                      },
                      {
                        column: "candidates.candidate_status",
                        value: 1,
                        condition: "orWhere",
                      },
                    ]}
                    isMultiSelect={true}
                  />
                </div>
              )}
            <div className="col-3">
              <SelectWithSearch
                search={true}
                options={dropDownData.functn}
                title={t("Function")}
                onChange={(e) => handleSelectChange(e, "functn")}
                isMulti={true}
                name="functn"
                value={formData.functn}
                isTranslate={true}
              />
            </div>
            {userAuth.role !== CANDIDATE_ROLE &&
              template !== "candidateCorner" && (
                <div className="col-md-3">
                  <SelectWithSearch
                    search={true}
                    options={dropDownData.stage}
                    title={t("Stage")}
                    onChange={(e) => handleSelectChange(e, "stage")}
                    isMulti={true}
                    name="stage"
                    value={formData.stage}
                    isTranslate={true}
                  />
                </div>
              )}
            <div className="col-3 mt-34 text-end">
              <div className="d-flex align-items-center">
                <ResetBtn handleResetClick={handleReset} />
                <Button
                  title={t("Search")}
                  type="submit"
                  icon={faMagnifyingGlass}
                  handleClick={handleSearch}
                  className="form-button shadow-none search-btns text-start position-relative"
                />
              </div>
            </div>
          </div>
        </div>

        <div className="table-responsive tableSection myTodosTable">
          <table className="table table-hover">
            <thead>
              <tr className="TableHeader">
                {userAuth.role !== CANDIDATE_ROLE &&
                  template !== "candidateCorner" && (
                    <th className="ps-lg-4">{t("Candidate")}</th>
                  )}
                {stage !== "screening" && <th>{t("Company")}</th>}
                <th>{t("Function")}</th>
                {type !== "completedtodos" &&
                  template !== "candidateCorner" &&
                  (!stage || stage === "new") && <th>{t("Stage")}</th>}
                {type !== "completedtodos" &&
                  template !== "candidateCorner" &&
                  (!stage || stage === "new") && <th>{t("Actions")}</th>}
                {template === "candidateCorner" &&
                  (stage === "presented" ||
                    (stage === "interview" && type === "mytodos")) && (
                    <th>{t("Action")}</th>
                  )}
              </tr>
            </thead>
            <tbody>
              {loading ? (
                <SpinnerWrapper headerLength={5} />
              ) : (
                <>
                  {type === "mytodos" ||
                  type === "completedtodos" ||
                  type === null ? (
                    <>
                      {todos && todos.length > 0 ? (
                        todos.map((todo) => (
                          <tr
                            key={todo.id}
                            className="border-bottom mb-3 box-shadow align-middle"
                          >
                            {userAuth.role !== CANDIDATE_ROLE &&
                              template !== "candidateCorner" && (
                                <td
                                  className="ps-lg-4"
                                  data-label={t("Candidate")}
                                >
                                  {todo.candidateName}
                                </td>
                              )}
                            {stage !== "screening" && (
                              <td data-label={t("Company")}>
                                {todo.companyName}
                              </td>
                            )}
                            <td data-label={t("Function")}>
                              {t(`${todo.function}`)}
                            </td>

                            {type !== "completedtodos" &&
                              template !== "candidateCorner" &&
                              !stage && (
                                <>
                                  <td data-label={t("Stage")}>
                                    <div>{t(`${todo.stage}`)}</div>
                                    <div className="link-color size-14px">
                                      {todo.status === 6 &&
                                        "Requested for rescheduling"}
                                    </div>
                                  </td>
                                </>
                              )}
                            {template === "candidateCorner" &&
                              (stage === "presented" ||
                                (stage === "interview" &&
                                  type === "mytodos")) && (
                                <>
                                  <td className="align-middle table-action-icons">
                                    <div className="d-md-none d-lg-block">
                                      {stage === "presented" && (
                                        <>
                                          <Link
                                            to={`/todos/presentation/candidate/single/${todo.vacancyId}/${todo.candidateId}/${todo.function}/${todo.companyId}/${todo.presentationId}?destination=/todos/overview`}
                                            state={{ origin: "todos" }}
                                          >
                                            <Button className="btn form-button rounded-3 shadow-none">
                                              {t("Present again")}
                                            </Button>
                                          </Link>
                                        </>
                                      )}
                                      {stage === "interview" &&
                                        (todo.stageId === 5 ||
                                          todo.stageId === 6 ||
                                          todo.stageId === 7) && (
                                          <>
                                            <Link
                                              to={`/edit/interview/details/${todo.candidateId}/${todo.companyId}/${todo.id}/${todo.todoId}/${todo.function}/${todo.vacancyId}/${todo.presentationId}`}
                                              state={{ origin: "todos" }}
                                              title={t("Edit")}
                                            >
                                              <EditIcon />
                                            </Link>
                                          </>
                                        )}
                                    </div>
                                  </td>
                                </>
                              )}
                            {type !== "completedtodos" &&
                              template !== "candidateCorner" &&
                              !stage && (
                                <td className="align-middle table-action-icons">
                                  {todo.status === 3 ? (
                                    <>
                                      <>
                                        <Link
                                          to={`/todos/presentation/candidate/single/${todo.vacancyId}/${todo.candidateId}/${todo.function}/${todo.companyId}/${todo.presentationId}?destination=/todos/overview`}
                                          state={{ origin: "todos" }}
                                        >
                                          <Button className="form-button shadow-none">
                                            {t("Present again")}
                                          </Button>
                                        </Link>
                                      </>
                                      <>
                                        <Link
                                          to={`/candidate/feedback/view/${todo.todoId}/${todo.id}/${todo.stageId}/${todo.candidateId}/${todo.presentationId}`}
                                          title={t("View")}
                                        >
                                          <ViewIcon />
                                        </Link>
                                      </>
                                    </>
                                  ) : (
                                    <>
                                      {userAuth.role !== CANDIDATE_ROLE &&
                                        userAuth.role !== EMPLOYER && (
                                          <>
                                            <Link to="">
                                              <span
                                                data-bs-toggle="tooltip"
                                                data-bs-placement="left"
                                                title={`${t("Assigned by")}: ${
                                                  todo.assignedBy
                                                } \n ${t("Assigned to")}: ${
                                                  todo.assignedTo
                                                }`}
                                                className="cursor-pointer"
                                              >
                                                <InfoIcon />
                                              </span>
                                            </Link>
                                            {(todo.stageId === 5 ||
                                              todo.stageId === 6 ||
                                              todo.stageId === 7) && (
                                              <>
                                                <Link
                                                  to={`/edit/interview/details/${todo.candidateId}/${todo.companyId}/${todo.id}/${todo.todoId}/${todo.function}/${todo.vacancyId}/${todo.presentationId}`}
                                                  state={{
                                                    origin: "todos",
                                                  }}
                                                  title={t("Edit")}
                                                >
                                                  <EditIcon />
                                                </Link>
                                              </>
                                            )}
                                            {todo.stageId > 3 &&
                                              todo.stageId < 9 && (
                                                <Link
                                                  to={`/candidate/feedback/create/${todo.todoId}/${todo.id}/${todo.stageId}/${todo.candidateId}/${todo.presentationId}?candidateName=${todo.candidateName}`}
                                                >
                                                  <span
                                                    title={t("Reject")}
                                                    className="link-button shadow-none"
                                                    data-bs-toggle="tooltip"
                                                    data-bs-placement="left"
                                                  >
                                                    <CloseFile />
                                                  </span>
                                                </Link>
                                              )}
                                          </>
                                        )}
                                      {userAuth.role !== AREA_MANAGER &&
                                        userAuth.role !== OFFICE_MANAGER &&
                                        (todo.stageId === 19 ||
                                          todo.stageId === 20) && (
                                          <>
                                            <Button
                                              handleClick={(event: any) =>
                                                assignTodo(
                                                  event,
                                                  todo.id,
                                                  "pickUpTodo",
                                                  todo.stageId,
                                                  todo.candidateId
                                                )
                                              }
                                              className=" border-0 bg-transparent marginRightPoint5 px-0"
                                              toolTipName={t("Pick up")}
                                            >
                                              <PickUp />
                                            </Button>
                                          </>
                                        )}
                                      {userAuth.role !== RECRUITER &&
                                        userAuth.role !== CANDIDATE_ROLE &&
                                        userAuth.role !== EMPLOYER &&
                                        todo.stageId !== 11 && (
                                          <>
                                            <Button
                                              handleClick={(event: any) =>
                                                openAssignTodoModal(
                                                  todo.todoId,
                                                  todo.id,
                                                  todo.stageId,
                                                  todo.candidateId
                                                )
                                              }
                                              className=" border-0 bg-transparent marginRightPoint5 px-0"
                                              toolTipName={t("Transfer to")}
                                            >
                                              <AssignTo />
                                            </Button>
                                          </>
                                        )}
                                      {(userAuth.userId === todo.assignedToId ||
                                        userAuth.role === EMPLOYER ||
                                        userAuth.role === AREA_MANAGER ||
                                        userAuth.role === OFFICE_MANAGER) &&
                                        todo.stageId !== 11 && (
                                          <>
                                            {todo.status !== 6 && (
                                              <Link
                                                to={getLinkForStage(
                                                  todo.id,
                                                  todo.stageId,
                                                  todo.todoId,
                                                  todo.candidateId,
                                                  todo.companyId,
                                                  todo.vacancyId,
                                                  todo.function,
                                                  todo.presentationId
                                                )}
                                                state={{ origin: "todos" }}
                                              >
                                                <span
                                                  data-bs-toggle="tooltip"
                                                  title={t("Open")}
                                                >
                                                  <RightArrow
                                                    width="2vw"
                                                    height="2vw"
                                                  />
                                                </span>
                                              </Link>
                                            )}
                                          </>
                                        )}
                                    </>
                                  )}
                                </td>
                              )}
                            {/* } */}
                          </tr>
                        ))
                      ) : (
                        <tr className="border rounded-3">
                          <td
                            colSpan={7}
                            className="border-0 text-center py-3 no-records"
                          >
                            <span className="text-danger w-100 d-block text-center">
                              {t("No tasks")}
                            </span>
                          </td>
                        </tr>
                      )}
                    </>
                  ) : (
                    <>
                      {newTodos && newTodos.length > 0 ? (
                        newTodos.map((newTodo) => (
                          <tr
                            key={newTodo.todoId}
                            className="border-bottom mb-3 box-shadow align-middle"
                          >
                            <td className="ps-lg-4" data-label={t("Candidate")}>
                              {newTodo.candidateName}
                            </td>
                            <td>--</td>
                            <td>{newTodo.functionNames}</td>
                            <td data-label={t("Registered")}>
                              {newTodo.stage}
                            </td>
                            <td className="table-action-icons">
                              {userAuth.role !== AREA_MANAGER &&
                                userAuth.role !== OFFICE_MANAGER &&
                                newTodo.stage === "New" && (
                                  <>
                                    <Button
                                      handleClick={(event: any) =>
                                        assignTodo(
                                          event,
                                          newTodo.todoId,
                                          "pickUpTodo",
                                          1,
                                          newTodo.candidateId
                                        )
                                      }
                                      className=" border-0 bg-transparent marginRightPoint5 px-0"
                                      toolTipName={t("Pick up")}
                                    >
                                      <PickUp />
                                    </Button>
                                  </>
                                )}
                              {userAuth.role !== "RECRUITER" &&
                                newTodo.stage === "New" && (
                                  <Button
                                    handleClick={(event: any) =>
                                      openAssignTodoModal(
                                        newTodo.todoId,
                                        null,
                                        1,
                                        newTodo.candidateId
                                      )
                                    }
                                    className=" border-0 bg-transparent marginRightPoint5 px-0"
                                    toolTipName={t("Assign to")}
                                  >
                                    <AssignTo />
                                  </Button>
                                )}
                              <Link
                                to=""
                                // className="todo-info-icon"
                              >
                                <span
                                  data-bs-toggle="tooltip"
                                  title={`${t("Registered on")} ${
                                    newTodo.registeredOn
                                  }`}
                                >
                                  <InfoIcon />
                                </span>
                              </Link>
                            </td>
                          </tr>
                        ))
                      ) : (
                        <tr className="border rounded-3">
                          <td
                            colSpan={7}
                            className="border-0 text-center py-3 no-records"
                          >
                            <span className="text-danger w-100 d-block text-center">
                              {t("No tasks")}
                            </span>
                          </td>
                        </tr>
                      )}
                    </>
                  )}
                </>
              )}
            </tbody>
          </table>
          {totalPages > 1 && (
            <div className="pagination justify-content-center align-items-center">
              <Pagination
                currentPage={currentPage}
                totalPages={totalPages}
                onPageChange={(newPage) => handlePageChange(newPage)}
              />
            </div>
          )}
        </div>
      </div>
      {userAuth.role !== CANDIDATE_ROLE && (
        <div className="col-md-6 align-self-center backPadding back-btn ">
          <Link
            to={
              location.state?.origin === "candidateCorner"
                ? `/candidate/corner/${candidateId}`
                : "/dashboard"
            }
            className="back-btn text-decoration-underline"
          >
            {t("Back")}
          </Link>
        </div>
      )}
      <div>
        <AssignTodoModal
          show={isAssignTodoModalOpen}
          onHide={closeAssignTodoModal}
          title={type === "newtodos" ? t("Assign to") : t("Transfer to")}
          todoId={todoId}
          todoExtensionId={todoExtensionId}
          onAssignTodoSuccess={handleAssignTodoSuccess}
          modalData={modalData}
          type={type}
        />
      </div>
      <ModalPopup
        show={showModal}
        showCloseButton={false}
        // onHide={handleModalClose}
        title={modal.title}
        body={modal.body}
        onConfirmButtonClick={handleSaveChanges}
        className="modal-lg"
        confirmTitle={t("Accept")}
        isInnerHTML={true}
      />
      <ModalPopup
        show={showSignatureModal}
        onHide={handleSignatureModalClose}
        title={t("Signature")}
        body={t("Please add signature")}
        onConfirmButtonClick={handleSignatureModal}
        className="modal-md"
        confirmTitle={t("Add")}
      />
    </>
  );
};
export default translate(TodosOverview);
