import { useEffect, useState } from "react";
import SelectWithSearch from "components/common/atoms/SelectWithSearch";
import { ApiCall } from "components/common/services/ApiServices";
import {
  Option,
  ValidationRules,
} from "components/common/utlis/TypeAnnotations";
import {
  validateForm,
  validateSelectField,
} from "components/common/services/ValidationService";
import Button from "components/common/atoms/Button";
import { Accordion } from "react-bootstrap";
import CheckBoxField from "components/common/atoms/CheckBoxField";
import RadioField from "components/common/atoms/RadioField";
import LabelWithTextAreaField from "components/common/molecules/LabelWithTextAreaField";
import { useParams } from "react-router-dom";
import CustomNotify from "components/common/atoms/CustomNotify";
import LoadingIcon from "components/common/utlis/LoadingIcon";
import AccessControl from "components/common/services/RolesAndPermissions/AccessControl";
import * as ENDPOINTS from "routes/ApiEndpoints";
import { t, translate } from "components/CentralDataMangement/translation/Translation";

interface PrepareQuestnProps {
  functions: object[];
  questions: any;
}

interface FeedbackProps {
  feedback: Option | null;
  feedbackInfo: string;
}

interface ChildProps {
  functions: Option[];
}

const Screening: React.FC<ChildProps> = ({ functions }) => {
  const [formData, setFormData] = useState<PrepareQuestnProps>({
    functions: functions,
    questions: [],
  });

  const { candidateId } = useParams<{ candidateId: string }>();

  const initialFeedbackErrors: { [key: string]: string } = {};
  const [feedbackErrors, setFeedbackErrors] = useState<{
    [key: string]: string;
  }>(initialFeedbackErrors);

  const [feedBackData, setFeedBackData] = useState<FeedbackProps>({
    feedback: null,
    feedbackInfo: "",
  });

  const [loading, setLoading] = useState(false);

  const validateFeedback = (
    name: string,
    value: string | boolean | Date | object[] | null | Option,
    isSingleFieldValidation: boolean = false
  ) => {
    const validationRules: ValidationRules = {
      feedback: [validateSelectField],
    };

    const validationErrors = validateForm(
      { ...feedBackData, [name]: value },
      validationRules,
      isSingleFieldValidation ? name : undefined
    );

    if (isSingleFieldValidation) {
      setFeedbackErrors((prevErrors) => ({
        ...prevErrors,
        [name]: validationErrors[name],
      }));
    } else {
      setFeedbackErrors(validationErrors);
    }

    if (Object.keys(validationErrors).length > 0) {
      return false;
    }
    return true;
  };

  const handleFieldChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    groupIndex: number,
    questionIndex: number
  ) => {
    const { name, type, value } = event.target;

    setFormData((prevData) => {
      const updatedQuestions = [...prevData.questions];

      if (type === "radio") {
        const answer = parseInt(value);
        updatedQuestions[groupIndex].questions[questionIndex].answers = [
          answer,
        ];
      } else if (type === "checkbox") {
        const answersList =
          updatedQuestions[groupIndex].questions[questionIndex].answers || [];

        if ((event.target as HTMLInputElement).checked) {
          if (!answersList.includes(parseInt(value))) {
            answersList.push(parseInt(value));
          }
        } else {
          // If the checkbox is unchecked, remove the value from the array
          const indexToRemove = answersList.indexOf(parseInt(value));
          if (indexToRemove !== -1) {
            answersList.splice(indexToRemove, 1);
          }
        }

        updatedQuestions[groupIndex].questions[questionIndex].answers = [
          ...answersList,
        ];
      } else if (name === "description") {
        updatedQuestions[groupIndex].questions[questionIndex].answers = [value];
      }

      return {
        ...prevData,
        questions: updatedQuestions,
      };
    });
  };

  const handleSelectChange = (selectedOption: any, fieldName: string) => {
    if (fieldName === "feedback") {
      setFeedBackData((prevData) => ({
        ...prevData,
        [fieldName]: selectedOption,
      }));
      validateFeedback(fieldName, selectedOption, true);
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [fieldName]: selectedOption,
      }));
      handleQuestions(selectedOption);
    }
  };

  const [activeKeys, setActiveKeys] = useState<string[]>([]);

  const handleQuestions = (selectedOption: any) => {
    const selectedValues = selectedOption.map((item: any) => item.value);

    setFormData((prevData) => {
      const updatedQuestions = prevData.questions.map(
        (questionGroup: any, index: number) => {
          if (selectedValues.includes(questionGroup.function.value)) {
            return {
              ...questionGroup,
              isDisabled: false,
            };
          } else {
            setActiveKeys((prevKeys: any) => {
              if (prevKeys?.includes(`${index}`)) {
                return prevKeys.filter((key: string) => key !== `${index}`);
              } else {
                return [...prevKeys];
              }
            });
            return {
              ...questionGroup,
              isDisabled: true,
            };
          }
        }
      );

      return {
        ...prevData,
        questions: updatedQuestions,
      };
    });
  };

  const handleDisableQuestion = (groupIndex: number, questionIndex: number) => {
    const updatedQuestions = [...formData.questions];
    const currentStatus =
      updatedQuestions[groupIndex].questions[questionIndex].disabled;

    updatedQuestions[groupIndex].questions[questionIndex].answers = [];
    updatedQuestions[groupIndex].questions[questionIndex].disabled =
      !currentStatus;

    setFormData({
      ...formData,
      questions: updatedQuestions,
    });
  };

  useEffect(() => {
    fetchQuestionsByFunction();
  }, [functions]);

  const fetchQuestionsByFunction = async () => {
    const postData = {
      functions: functions,
      questions: []
    }
    const response = await ApiCall.service(
      `${ENDPOINTS.GET_QUESTIONS_BY_FUNCTIONS}`,
      "POST",
      postData,
      false,
      "central-data-management"
    );
    if (response.status === 200) {
      setFormData((prevData) => ({
        ...prevData,
        questions: response.data,
      }));
    } else if (response.status === 400) {
      CustomNotify({
        type: "error",
        message: t(response.data),
      });
      if (formData.questions.length > 0) {
        setFormData((prevData) => ({
          ...prevData,
          questions: [],
        }));
      }
    }
  };

  // Toggle a specific accordion item
  const toggleAccordion = (groupIndex: number) => {
    setActiveKeys((prevKeys) => {
      if (prevKeys?.includes(`${groupIndex}`)) {
        // If the item is already open, close it
        return prevKeys.filter((key) => key !== `${groupIndex}`);
      } else {
        // Otherwise, open it
        return [...prevKeys, `${groupIndex}`];
      }
    });
  };

  const handleSubmit = async (
    e: React.FormEvent<HTMLFormElement> | React.MouseEvent<HTMLButtonElement>
  ) => {
    setLoading(true);
    e.preventDefault();
    console.log(candidateId, 'candidateId')
    const { name, value } = e.target as HTMLInputElement;
    if (validateFeedback(name, value)) {
      const data = {
        feedBackData: feedBackData,
        candidateId: candidateId,
        functionQuestions: formData.questions
          .filter((group: any) => !group.isDisabled)
          .map((group: any) => ({
            function: group.function,
            questions: group.questions
              .filter((question: any) => !question.disabled) // Include only questions with disabled: false
              .map((question: any) => ({
                questionId: question.questionId,
                questionType: question.questionType,
                answers: question.answers,
              })),
          })),
      };
      // Check if all question arrays are empty
      const allArraysEmpty = data.functionQuestions.every(
        (group: any) => group.questions.length === 0
      );

      // If all arrays are empty, set an error message and return
      if (allArraysEmpty) {
        CustomNotify({
          type: "error",
          message: t("No questions added for the selected functions"),
        });
        setLoading(false);
        return;
      }

      const response = await ApiCall.service(
        `${ENDPOINTS.STORE_SCREENING_INFO}`,
        "POST",
        data,
        false,
        "central-data-management"
      );
      if (response.status === 200) {
        CustomNotify({
          type: "success",
          message: t(response.msg),
        });
      }
    }

    setLoading(false);
  };

  const handleFeedBackFieldChange = (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >
  ) => {
    const { name, value, type } = e.target;
    setFeedBackData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const feedbackOptions = [
    { value: 1, label: t("Excellent") },
    { value: 2, label: t("Very good") },
    { value: 3, label: t("Good") },
    { value: 4, label: t("Average") },
    { value: 5, label: t("Poor") },
  ];

  return (
    <>
      <AccessControl
        requiredPermissions={[
          {
            permission: "Candidate",
            read: true,
          },
        ]}
        renderNoAccess={true}
      >
        <div className="form-border px-5 border">
          <div className="form-height-screening">
            {formData.questions.length > 0 && (
              <div className="mb-3">
                {formData.questions.map(
                  (questionGroup: any, groupIndex: number) => (
                    <div key={groupIndex}>
                      <div className="margnBotttom1">
                        <Accordion activeKey={activeKeys}>
                          <Accordion.Item eventKey={`${groupIndex}`}>

                            <Accordion.Header
                              onClick={() => !questionGroup.isDisabled && toggleAccordion(groupIndex)}
                              className={`fw-bold ${questionGroup.isDisabled ? "opacity-50" : ""}`}
                            >
                              {t(`${questionGroup.function.label}`)}
                            </Accordion.Header>

                            <Accordion.Body className="bg-white padding1">
                              {questionGroup.questions.length &&
                                questionGroup.questions.length > 0 ? (
                                <>
                                  {questionGroup.questions.map(
                                    (question: any, index: number) => (
                                      <div
                                        key={index}
                                        className={`marginBotttom1 internalContentWrapper border ${question.disabled
                                          ? " border question-not-applicable-section opacity-50 marginBottom1 interbalContebtWrapper"
                                          : ""}`}
                                      >
                                        <div className="d-flex justify-content-between align-items-center">
                                          <h5
                                            className="questionName"
                                            dangerouslySetInnerHTML={{ __html: index + 1 + ") " + question.question }}
                                          />
                                          <Button
                                            className="btn py-1 px-3 border-0 rounded-2 text-end not-applicable-action"
                                            handleClick={() => handleDisableQuestion(groupIndex, index)}
                                          >
                                            {t("N/A")}
                                          </Button>
                                        </div>

                                        {question.questionType === 1 &&
                                          question.options.length > 0 && (
                                            <div className="my-1" key={index}>
                                              {question.options.map((option: any, optionIndex: number) => (
                                                <div
                                                  key={optionIndex}
                                                  className="screening-checkbox d-flex align-items-center mb-2"
                                                >
                                                  <CheckBoxField
                                                    name={`answers-${index}-${optionIndex}`}
                                                    id={`answers-${index}-${optionIndex}`}
                                                    onChangeHandler={(event) => handleFieldChange(event, groupIndex, index)}
                                                    value={optionIndex}
                                                    disable={question.disabled}
                                                    isChecked={question.answers.includes(optionIndex)}
                                                  />
                                                  <span
                                                    dangerouslySetInnerHTML={{ __html: option.replace(/<a/g, '<a target="_blank"'), }}
                                                  />
                                                </div>
                                              )
                                              )}
                                            </div>
                                          )}

                                        {question.questionType === 3 &&
                                          question.options.length > 0 && (
                                            <div className="my-1" key={index}>
                                              {question.options.map((option: any, optionIndex: number) => (
                                                <div
                                                  key={optionIndex}
                                                  className="d-flex screening-checkbox align-items-center mb-2"
                                                >
                                                  <RadioField
                                                    className="ps-1"
                                                    id={`answers-${index}-${optionIndex}`}
                                                    value={optionIndex}
                                                    name={`answers-${groupIndex}-${index}`}
                                                    handleChange={(event) => { handleFieldChange(event, groupIndex, index); }}
                                                    disable={question.disabled}
                                                    ischecked={question.answers.includes(optionIndex)}
                                                  />
                                                  <span
                                                    dangerouslySetInnerHTML={{ __html: option }}
                                                  />
                                                </div>
                                              ))}
                                            </div>
                                          )}

                                        {question.questionType === 2 && (
                                          <LabelWithTextAreaField
                                            name="description"
                                            handleChange={(event) => handleFieldChange(event, groupIndex, index)}
                                            label=""
                                            value={formData.questions[groupIndex].questions[index].answers[0]}
                                            isDisabled={question.disabled}
                                          />
                                        )}
                                      </div>
                                    )
                                  )}
                                </>
                              ) : (
                                <div className="text-danger p-2 text-center">
                                  {t(
                                    "No general and function related questions added"
                                  )}
                                </div>
                              )}
                            </Accordion.Body>

                          </Accordion.Item>
                        </Accordion>
                      </div>
                    </div>
                  )
                )}
              </div>
            )}

            {/* Feedback */}
            {formData.questions.length > 0 && (
              <div className="row">
                <div className="col-md-6">
                  <SelectWithSearch
                    title={t("Screening feedback")}
                    isMandatory={true}
                    search={true}
                    options={feedbackOptions}
                    placeHolder={t("Select")}
                    onChange={(e) => handleSelectChange(e, "feedback")}
                    isMulti={false}
                    className="select-field"
                    name="feedback"
                    value={feedBackData.feedback}
                    error={feedbackErrors.feedback}
                    isDisabled={false}
                    isTranslate={true}
                  />
                </div>
                <div className="col-md-12">
                  <LabelWithTextAreaField
                    name="feedbackInfo"
                    handleChange={handleFeedBackFieldChange}
                    label={t("Feedback info")}
                    value={feedBackData.feedbackInfo}
                  />
                </div>
              </div>
            )}
          </div>
          <div className="row backPadding">
            <div className="col-md-12 text-end">
              {!loading ? (
                <Button
                  title={t("Save")}
                  type="submit"
                  className="float-end form-button shadow-none"
                  handleClick={handleSubmit}
                />
              ) : (
                <LoadingIcon
                  iconType="bars"
                  color="#e55496"
                  className="ms-auto"
                  height="3vw"
                  width="3vw"
                />
              )}
            </div>
          </div>
        </div>
      </AccessControl>
    </>
  );
};
export default translate(Screening);
