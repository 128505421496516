import { Option, OptionProps } from "components/common/utlis/TypeAnnotations";

export interface CandidateSearchData {
  office: number[];
  firstName: string;
  lastName: string;
  consultant: string;
  street: string;
  postalCode: string;
  email: string;
  phone: string;
  from: Date | null | string;
  to: Date | null | string;
  hotlisted: boolean | null | string;
  orderByColumn: string;
  orderIn: "" | "asc" | "desc"; // Ensure correct typing here
  currentPage: number;
  recordsPerPage: number;
  archiveStatus: number | null;
  candidateType: number | null;
}

export const intialCandidateSearchData: CandidateSearchData = {
  office: [],
  firstName: "",
  lastName: "",
  consultant: "",
  street: "",
  postalCode: "",
  email: "",
  phone: "",
  from: "",
  to: "",
  orderByColumn: "",
  orderIn: "",
  hotlisted: null,
  currentPage: 1,
  recordsPerPage: 10,
  archiveStatus: null,
  candidateType: null,
};

export interface candidateAdvSearchData {
  ssn: string;
  createdAt: Date | null | string;
  iban: string;
  function: Option | null;
  regime: Option | null;
  city: string;
  country: string;
  extraRef: string;
  functnAliasName: string;
  selectionGroup: Option | null;
  source: Option | null;
}

export const defaultCandidateAdvSearchData: candidateAdvSearchData = {
  ssn: "",
  createdAt: "",
  iban: "",
  function: null,
  regime: null,
  city: "",
  country: "",
  extraRef: "",
  functnAliasName: "",
  selectionGroup: null,
  source: null,
};

export interface dropDownList {
  offices: OptionProps[];
  sources: OptionProps[];
  selectionGroups: OptionProps[];
  statuses: OptionProps[];
  functions: OptionProps[];
  regimes: OptionProps[];
}

export const intialDropDownList: dropDownList = {
  offices: [] as OptionProps[],
  sources: [] as OptionProps[],
  selectionGroups: [] as OptionProps[],
  statuses: [] as OptionProps[],
  functions: [] as OptionProps[],
  regimes: [] as OptionProps[],
};

export interface CandidateData {
  candidateId?: number;
  status: number | undefined;
  formStatus: number | undefined;
  name: string;
  firstName: string;
  lastName: string;
  consultant: string;
  street: string;
  postalCode: string;
  email: string;
  phone: string;
  hotlist_id: number | null;
  hotlisted: boolean | null;
  office: string;
  officename: string;
  type: string;
  officeId: string | null;
  from?: Date | null | string;
  to?: Date | null | string;
  created: string;
  userId: number;
  recordingStatus: number | null;
  brightId: number | null;
  created_by: string;
  updated_by: string;
  updated_at: Date | null;
  created_at: Date | null;
  consultantId: number;
  is3daysago: boolean;
  postScreeningStatus: number | null;
}
